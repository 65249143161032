export const currentUser = {
  id: 1121,
  email: 'somemail@gmail.com',
  first_name: 'Name',
  last_name: 'Surename',
  account_id: 629,
};

export const isAdmin = true;

export const videoItem = {
  id: 90,
  type: 'Video',
  domain: 'vimeo',
  title: 'Case of the Week #22:  Oracle USA, Inc. v. Rimini Street, Inc.',
  description: 'some description',
  url: 'https://vimeo.com/542230467',
  author: 'Kelly Twigger',
  tags: [{ name: 'first tag' }, { name: 'second tag' }],
  post_body: '<p>Some post text</p>',
  thumbnail: 'http://i.vimeocdn.com/video/1421525145-9e3bd36811e2d1c0d62bff2f3716d50abe14bde8688fca0dafe300e224e89f41-d_640',
  media_items: [],
};

export const documentItem = {
  id: 24,
  type: 'Article',
  domain: '',
  title: '2015 Summary of Federal Rules Amendments 1',
  description: 'some description',
  url: '',
  author: 'Kelly Twigger',
  tags: [],
  post_body: '<p>Some post text</p>',
  document: { url: '111.docx' },
  post_header_image: 'video-production_0.jpg',
  media_items: [],
};

export const postItem = {
  id: 19,
  type: 'Post',
  domain: '',
  title: '2015 Summary of Federal Rules Amendments 1',
  description: 'some description',
  author: 'Kelly Twigger',
  post_body: '<p>Some post text</p>',
  tags: [],
  media_items: [],
  post_photo: { url: 'budget.jpeg' },
  post_header_image: { url: 'budget.jpeg' },
};

export const mediaItems = [videoItem, documentItem, postItem];

export const topics = [
  {
    id: '17',
    name: 'Soapbox',
    description: 'some description',
  },
  {
    id: '18',
    name: 'Resources',
    description: 'some description',
  },
];

export const tags = [
  {
    id: 75,
    name: 'Blackbox',
    subtype: 'label',
    issue_category_id: 155,
    issue_category: {
      id: 155,
      name: 'Source of ESI',
      positition: 1,
    },
  },
  {
    id: 26,
    name: 'Bad Faith',
    subtype: 'issue',
    issue_category_id: 153,
    issue_category: {
      id: 153,
      name: 'Sanctions',
      positition: 4,
    },
  },
];

export const searchResults = [
  {
    ...videoItem, media_category_ids: [19],
  },
  {
    ...postItem, media_category_ids: [4, 16],
  },
];

export const linkedContent = {
  CaseDigest: [{
    slug: 'some_slug',
    published_revision: { name: 'some name' },
    state_rules: [{ id: 1, type: 'RegulatoryRule', published_revision: { number: '123', name: '123' } }],
    federal_rules: [{ id: 2, type: 'FederalRule', published_revision: { number: '123', name: '123' } }],
    district_rules: [{ id: 3, type: 'DistrictRule', published_revision: { number: '123', name: '123' } }],
    additional_filings: [{ slug: 'some_add_slug', published_revision: { name: 'some add name', cite: 'some cite', decision_date: '2017-11-05T23:09:53.005-07:00' } }],
    checklists: [{ id: 1, published_revision: { name: 'some name' } }],
    templates: [{ published_revision: { document: { url: 'some url' } } }],
    tips: [{ name: 'some name', description: 'some description' }],
  }],
  Rule: [
    { id: 1, type: 'RegulatoryRule', published_revision: { number: '123', name: '123' } },
    { id: 2, type: 'FederalRule', published_revision: { number: '123', name: '123' } },
  ],
  Checklist: [{ id: 1, published_revision: { name: 'some name' } }],
  Template: [{ id: 1, published_revision: { document: { url: 'some url' } } }],
  GlossaryTerm: [{ published_revision: { id: 1, name: 'some name' } }],
};
