// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="react-history"
>
  <div
    className="left"
  >
    <div
      className="history-header"
    >
      <div
        className="name"
      >
        History
      </div>
      <div
        className="category all-category  selected"
        data-testid="all"
        onClick={[Function]}
      >
        <div
          className="category-name"
        >
          All Results (2157)
        </div>
      </div>
    </div>
    <div
      className="category"
      data-testid="search"
      onClick={[Function]}
    >
      <div
        className="category-name"
      >
        <div
          className="category-image"
        >
          <div
            className="case_digests"
            style={
              Object {
                "height": 20,
                "width": 20,
              }
            }
          />
          <span>
            Case Law Search
          </span>
        </div>
        <div
          className="category-count"
        >
          <span>
            1473
          </span>
        </div>
      </div>
    </div>
    <div
      className="category"
      data-testid="rules"
      onClick={[Function]}
    >
      <div
        className="category-name"
      >
        <div
          className="category-image"
        >
          <div
            className="rules"
            style={
              Object {
                "height": 20,
                "width": 20,
              }
            }
          />
          <span>
            Rules
          </span>
        </div>
        <div
          className="category-count"
        >
          <span>
            226
          </span>
        </div>
      </div>
    </div>
    <div
      className="category"
      data-testid="checklists_and_forms"
      onClick={[Function]}
    >
      <div
        className="category-name"
      >
        <div
          className="category-image"
        >
          <div
            className="checklists_and_forms"
            style={
              Object {
                "height": 20,
                "width": 20,
              }
            }
          />
          <span>
            Checklists & Forms
          </span>
        </div>
        <div
          className="category-count"
        >
          <span>
            445
          </span>
        </div>
      </div>
    </div>
    <div
      className="category"
      data-testid="glossary_terms"
      onClick={[Function]}
    >
      <div
        className="category-name"
      >
        <div
          className="category-image"
        >
          <div
            className="glossary"
            style={
              Object {
                "height": 20,
                "width": 20,
              }
            }
          />
          <span>
            Glossary
          </span>
        </div>
        <div
          className="category-count"
        >
          <span>
            13
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    className="right"
  >
    <div
      className="loader modal-content"
    >
      <div
        className="css-8mq0zr"
      />
      <div
        className="name"
      >
        Loading
      </div>
    </div>
    <div
      className="right-content"
    >
      <div>
        <div
          className="selected-category-header"
        >
          <div
            className="header-info"
          >
            <span
              className="selected-category-title"
            >
              All Results
            </span>
            <span
              className="selected-category-count"
            >
              0
            </span>
          </div>
          <div
            className="filters"
          >
            <div
              className="filter-select"
            >
              <span>
                Per page:
              </span>
              <select
                onChange={[Function]}
                value={25}
              >
                <option
                  value={10}
                >
                  10
                </option>
                <option
                  value={25}
                >
                  25
                </option>
                <option
                  value={50}
                >
                  50
                </option>
              </select>
            </div>
            <div
              className="filter-select"
            >
              <select
                onChange={[Function]}
                value=""
              >
                <option
                  value="7days"
                >
                  Last 7 Days
                </option>
                <option
                  value="30days"
                >
                  Last 30 Days
                </option>
                <option
                  value="year"
                >
                  Last Year
                </option>
                <option
                  value=""
                >
                  All Time
                </option>
              </select>
            </div>
          </div>
        </div>
        <div
          className="category"
        />
        <div
          className="pagination-buttons"
        >
          <button
            onClick={[Function]}
          >
            Next Page
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
