import React from 'react';
import PropTypes from 'prop-types';
import { FaBookmark } from 'react-icons/all';
import api from '../../services/api';
import CaseDigestPreview from '../case_digest/CaseDigestPreview';

function getFavoriteName(favorite) {
  if (favorite.type === 'AbaModelRule'
    || favorite.type === 'StateRule'
    || favorite.type === 'FederalRule'
    || favorite.type === 'DistrictRule'
    || favorite.type === 'RegulatoryRule') return favorite.number;

  return favorite.name;
}

class Bookmarks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 'all',
      sortBy: 'favorited_at',
      favorites: props.favorites,
    };
  }

  componentDidMount() {
    this.onSort('favorited_at');
  }

  _setSelectedCategory = (category) => {
    this.setState({ selectedCategory: category });
  };

  _removeBookmark = (e, category, favorite) => {
    e.preventDefault();
    e.stopPropagation();
    switch (category) {
      case 'rules':
        this._removeRuleBookmark(favorite, favorite.type);
        break;
      case 'glossary_terms':
        this._removeGlossaryTermBookmark(favorite);
        break;
      case 'cite_lists':
        this.toggleCiteListBookmark(favorite);
        break;
      case 'case_digests':
        this.removeCaseBookmark(favorite);
        break;
      case 'checklists_and_forms':
        this.removeChecklistAndFormBookmark(favorite);
        break;
      default:
        break;
    }
  };

  removeCaseBookmark = (caseDigest) => {
    const { favorites } = this.state;
    api.case_digests.unfavorite(caseDigest.case_digest_id).then(() => this.setState({ favorites: favorites.filter((c) => c !== caseDigest) }));
  };

  removeChecklistAndFormBookmark = (item) => {
    const { favorites } = this.state;
    const type = item.checklist_id ? 'checklists' : 'forms';
    const id = item.checklist_id || item.template_id;

    api[type].unfavorite(id).then(() => this.setState({ favorites: favorites.filter((c) => c !== item) }));
  };

  toggleCiteListBookmark = (citeList) => {
    const { favorites } = this.state;

    api.cite_lists.favorite(citeList.id)
      .then((res) => {
        if (res.success === true) {
          this.setState({ favorites: favorites.filter((favoriteList) => favoriteList !== citeList) });
        }
      });
  };

  _removeGlossaryTermBookmark = (term) => {
    const { favorites } = this.state;

    api.glossary.unfavorite(term.glossary_term_id).then((res) => {
      if (res.success === true) {
        this.setState({ favorites: favorites.filter((favoriteTerm) => favoriteTerm !== term) });
      }
    });
  };

  _updateRuleFavoritesInState = (rule, result) => {
    const { favorites } = this.state;
    if (result.success === true) {
      this.setState({ favorites: favorites.filter((r) => r !== rule) });
    }
  };

  _removeRuleBookmark = (rule, ruleType) => {
    const ruleId = rule.rule_id;

    switch (ruleType) {
      case 'FederalRule':
        api.rules.federal.unfavorite(ruleId).then((res) => this._updateRuleFavoritesInState(rule, res));
        break;
      case 'StateRule':
        api.rules.state.unfavorite(ruleId).then((res) => this._updateRuleFavoritesInState(rule, res));
        break;
      case 'DistrictRule':
        api.rules.district.unfavorite(ruleId).then((res) => this._updateRuleFavoritesInState(rule, res));
        break;
      case 'AbaModelRule':
        api.rules.aba.unfavorite(ruleId).then((res) => this._updateRuleFavoritesInState(rule, res));
        break;
      case 'RegulatoryRule':
        api.rules.regulatory.unfavorite(ruleId).then((res) => this._updateRuleFavoritesInState(rule, res));
        break;
      default:
        break;
    }
  };

  _transformCategory = (category) => {
    if (category === 'rules') return 'Rules';
    if (category === 'glossary_terms') return 'Glossary';
    if (category === 'checklists_and_forms') return 'Checklists & Forms';
    if (category === 'case_digests') return 'Case Law';
    if (category === 'cite_lists') return 'Cite Lists';
    return null;
  };

  _getLink = (favorite, category) => {
    if (category === 'checklists_and_forms' && favorite.checklist_id) return `/checklists/${favorite.checklist_id}`;
    if (category === 'checklists_and_forms' && favorite.template_id) return `/forms/${favorite.template_id}`;
    if (category === 'case_digests') return `/case_law/${favorite.slug}`;
    if (category === 'rules') {
      if (favorite.type === 'AbaModelRule') return `/aba_model_rules/${favorite.rule_id}`;
      if (favorite.type === 'StateRule') return `/state_rules/${favorite.rule_id}`;
      if (favorite.type === 'FederalRule') return `/federal_rules/${favorite.rule_id}`;
      if (favorite.type === 'DistrictRule') return `/district_rules/${favorite.rule_id}`;
      if (favorite.type === 'RegulatoryRule') return `/regulatory_rules/${favorite.rule_id}`;
    }
    if (category === 'glossary_terms') return `/glossary_terms?term=${favorite.id}`;
    if (category === 'cite_lists') return `/cite_lists/${favorite.id}`;
    return '';
  };

  onSort = (value) => {
    const { favorites } = this.state;
    if (value === 'favorited_at') {
      this.setState({
        sortBy: value,
        favorites: [...favorites.sort((a, b) => new Date(b.favorited_at) - new Date(a.favorited_at))],
      });
    }

    if (value === 'name') {
      this.setState({
        sortBy: value,
        favorites: [...favorites.sort((a, b) => getFavoriteName(a).localeCompare(getFavoriteName(b)))],
      });
    }
  };

  renderRightContent = () => {
    const { favorites, sortBy } = this.state;
    const { selectedCategory } = this.state;
    const displayFavorites = selectedCategory === 'all' ? favorites : favorites.filter((f) => f.category === selectedCategory);

    return (
      <div className="right-content">
        <div className="selected-category-header">
          { selectedCategory === 'all' ? <span className="selected-category-title">All Results</span> : <span className="selected-category-title">{this._transformCategory(selectedCategory)}</span>}
          <span className="selected-category-count">{displayFavorites.length}</span>
          <div style={{ flex: 1 }} />
          <div className="results-sort tag">
            Sort By:
            <select
              value={sortBy}
              onChange={(e) => this.onSort(e.target.value)}
            >
              <option value="favorited_at">Recently Added</option>
              <option value="name">Alphabetical</option>
            </select>
          </div>
        </div>

        { displayFavorites.length === 0 && this.renderNoBookmarks() }
        { displayFavorites.length > 0 && (
        <div>
          <div className="list-header">
            <span className="list-header-entry">ENTRY</span>
            <span className="list-header-name">NAME / TITLE</span>
          </div>
          <div className="category">
            { displayFavorites.map((favorite) => (
              favorite.category === 'cite_lists' && favorite.published_revision ? (
                <div className="bookmark" key={favorite.id}>
                  <div className="top">
                    <div className="bookmark-type">Cite Lists</div>
                    <CaseDigestPreview
                      caseDigest={favorite}
                      onClick={(e) => e.stopPropagation()}
                      onSelect={() => {}}
                      slug={favorite.slug}
                      selectedCiteListCases={[]}
                      isCaseSelected={false}
                      hasTagList
                      isInBookmarks
                      isFavorite
                      onToggleFavorite={() => {}}
                    />
                  </div>
                </div>
              ) : (
                <div className="bookmark" key={favorite.id}>
                  <a href={this._getLink(favorite, favorite.category)} className="top">
                    <div className="bookmark-type">{this._transformCategory(favorite.category)}</div>
                    <div className="bookmark-name">
                      {getFavoriteName(favorite)}
                    </div>
                    <FaBookmark onClick={(e) => this._removeBookmark(e, favorite.category, favorite)} />
                    { (favorite.category === 'cite_lists' && Number(favorite.shared_users) > 0) ? (
                      <div className="shared-users tag">
                        Shared with
                        {' '}
                        { favorite.shared_users }
                        {' '}
                        users
                      </div>
                    ) : ''}
                  </a>
                </div>
              )
            ))}
          </div>
        </div>
        )}
      </div>
    );
  };

  renderNoBookmarks = () => (
    <div className="no-bookmarks">
      <div className="no-bookmarks-text">
        <span>You have no bookmarks added.</span>
        <span>Select a database below to start exploring.</span>
      </div>
      <div className="no-bookmarks-navigation">
        <a href="/case_law">
          <div className="no-bookmarks-link">
            <div className="background-case-law-image" style={{ height: 50, width: 50 }} />
            <span>Case Law</span>
          </div>
        </a>
        <a href="/rules">
          <div className="no-bookmarks-link">
            <div className="background-rules-image" style={{ height: 50, width: 50 }} />
            <span>Rules</span>
          </div>
        </a>
        <a href="/checklists">
          <div className="no-bookmarks-link">
            <div className="background-checklists-image" style={{ height: 50, width: 50 }} />
            <span>Checklists & Forms</span>
          </div>
        </a>
        <a href="/glossary_terms">
          <div className="no-bookmarks-link">
            <div className="background-glossary-image" style={{ height: 50, width: 50 }} />
            <span>Glossary</span>
          </div>
        </a>
        <a href="/cite_lists">
          <div className="no-bookmarks-link">
            <div className="background-cite-list-image" style={{ height: 50, width: 50 }} />
            <span>Cite Lists</span>
          </div>
        </a>
      </div>
    </div>
  );

  render() {
    const { favorites } = this.state;
    const { selectedCategory } = this.state;
    return (
      <div className="react-bookmarks">
        <div className="left">
          <div className="bookmarks-header">
            <div className="name">
              Bookmarks
            </div>
            <div
              className={`category all-category ${selectedCategory === 'all' ? ' selected' : ''}`}
              onClick={() => this._setSelectedCategory('all')}
            >
              <div className="category-name">{`All Results (${favorites.length})`}</div>
            </div>
          </div>
          <div className="categories">
            { ['case_digests', 'rules', 'checklists_and_forms', 'glossary_terms', 'cite_lists'].map((category) => (
              <div
                className={`category${selectedCategory === category ? ' selected' : ''}`}
                key={category}
                onClick={() => this._setSelectedCategory(category)}
              >
                <div className="category-name">
                  <div className="category-image">
                    <div className={category} style={{ height: 20, width: 20 }} />
                    <span>{ this._transformCategory(category) }</span>
                  </div>
                  <div className="category-count">
                    <span data-testid={category}>{`${favorites.filter((f) => f.category === category).length}`}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="right">
          <div className="right-content">
            {this.renderRightContent()}
          </div>
        </div>
      </div>
    );
  }
}

Bookmarks.propTypes = {
  favorites: PropTypes.array.isRequired,
};

export default Bookmarks;
