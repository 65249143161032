import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import api from '../../services/api';

export default function FeedbackModal({ closeModal }) {
  const [text, setText] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendFeedback = () => {
    setLoading(true);
    api.send_feedback(text)
      .then((response) => {
        setLoading(false);
        if (response.success) setIsSent(true);
        else alert('Something went wrong.');
      });
  };

  return (
    <div className="login-modal new-modal" onClick={closeModal} data-testid="login-modal">
      <div className="modal-dialog modal-md">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="circle" />
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            { loading && (
              <div className="loader">
                <ClipLoader color="rgba(0, 14, 87, 1)" />
                <div className="name">Loading</div>
              </div>
            )}
            {(!loading && isSent) && (
              <p>
                Thank you for your feedback. Your message was delivered successfully.
              </p>
            )}
            { (!loading && !isSent) && (
              <>
                <p style={{ width: '70%', margin: 'auto' }}>
                  Please send us your feedback, comments, questions or suggestions for additional features that would
                  improve eDA for you.
                </p>
                <textarea
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Add text here."
                  className="feedback-input"
                />
                <div className="button-row">
                  <button className="modal-button" onClick={sendFeedback}>
                    Send Feedback
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

FeedbackModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
