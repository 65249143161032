import React, { useEffect, useRef, useState } from 'react';
import {
  FiUpload, FiEye, FiPlus, FiEdit3, FiTrash, FiArrowLeft,
} from 'react-icons/fi';
import {
  GrDrag,
} from 'react-icons/gr';
import SortableTree, {
  isDescendant, removeNodeAtPath, defaultGetNodeKey, changeNodeAtPath,
} from 'react-sortable-tree';
import classnames from 'classnames';
import 'react-sortable-tree/style.css';
import Api from '../../../services/api';
import Select from '../Select';
import Dropdown, { DropdownItem } from '../Dropdown';
import CategorySidebar from '../categories/Sidebar';
import TipsSidebar from '../tips/Sidebar';
import EdaSwitch from '../Switch';

function parseChecklistItems(items) {
  return items.map((item) => ({
    text: item.text,
    tip_ids: item.tip_ids,
    heading: item.heading,
    expanded: true,
    children: parseChecklistItems(item.children),
  }));
}

export default function Edit({
  checklists, forms, checklist, published,
}) {
  const description = useRef();
  const [data, setData] = useState({
    name: checklist?.name || '',
    category_ids: checklist?.category_ids || [],
    checklist_ids: checklist?.checklist_ids || [],
    template_ids: checklist?.template_ids || [],
  });

  const [categories, setCategories] = useState([]);
  const [tips, setTips] = useState([]);
  const [type, setType] = useState('checklist');
  const [items, setItems] = useState([]);
  const [editableItem, setEditableItem] = useState(null);

  const [categorySidebarVisible, setCategorySidebarVisible] = useState(null);
  const [editableCategory, setEditableCategory] = useState(null);

  const [tipSidebarVisible, setTipSidebarVisible] = useState(null);
  const [editableTip, setEditableTip] = useState(null);

  const fetchCategories = () => {
    Api.admin.categories.getAll().then((res) => {
      if (res.status !== 'OK') return;
      setCategories(res.data);
    });
  };

  const fetchTips = () => {
    Api.admin.tips.getAll().then((res) => {
      if (res.status !== 'OK') return;
      setTips(res.data);
    });
  };

  useEffect(() => { fetchCategories(); }, []);
  useEffect(() => { fetchTips(); }, []);

  useEffect(() => {
    description.current.innerHTML = checklist?.description || '';
  }, [description]);

  useEffect(() => {
    if (checklist) setItems(parseChecklistItems(checklist.checklist_items));
  }, [checklist]);

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleAddChecklistItem = () => {
    setEditableItem({
      text: '',
      tip_ids: [],
      heading: false,
      children: [],
    });
  };

  const handleAddChecklistHeading = () => {
    setEditableItem({
      text: '',
      tip_ids: [],
      heading: true,
      children: [],
    });
  };

  const handleEditChecklistItem = (item, path) => {
    setEditableItem({ ...item, path });
  };

  const handleSaveChecklistItem = () => {
    const { path, ...item } = editableItem;
    if (path) {
      setItems(
        changeNodeAtPath({
          treeData: items,
          path,
          newNode: { ...item, expanded: true },
          getNodeKey: defaultGetNodeKey,
        }),
      );
    } else {
      setItems([...items, { ...editableItem, expanded: true }]);
    }

    setEditableItem(null);
  };

  const handleChecklistItemChange = (field, value) => {
    setEditableItem({ ...editableItem, [field]: value });
  };

  const handleSwitch = () => {
    setType(type === 'checklist' ? 'form' : 'checklist');
  };

  const handleUpdate = () => {
    if (checklist) {
      Api.admin.checklists.update(checklist.checklist_id, {
        name: data.name,
        description: description.current.innerHTML,
        category_ids: data.category_ids,
        checklist_ids: data.checklist_ids,
        template_ids: data.template_ids,
        items,
      }).then((res) => {
        if (res.status !== 'OK') sessionStorage.setItem('message', res.error);
        else sessionStorage.setItem('message', 'Checklist updated');
        window.location.reload();
      });
    } else {
      Api.admin.checklists.create({
        name: data.name,
        description: description.current.innerHTML,
        category_ids: data.category_ids,
        checklist_ids: data.checklist_ids,
        template_ids: data.template_ids,
        items,
      }).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', 'Checklist created');
        window.location.href = `/admin/checklists/${res.checklist.id}/edit`;
      });
    }
  };

  const handleCategoryUpdate = () => {
    setCategorySidebarVisible(false);
    setEditableCategory(null);
    fetchCategories();
  };

  const handleTipUpdate = () => {
    setTipSidebarVisible(false);
    setEditableTip(null);
    fetchTips();
  };

  const handlePublish = () => {
    if (!checklist) return;

    Api.admin.checklists.publish(checklist.checklist_id).then((res) => {
      if (res.status !== 'OK') {
        sessionStorage.setItem('message', 'Checklist has been published');
        return;
      }
      sessionStorage.setItem('message', 'Checklist has been published');
      window.location.reload();
    });
  };

  const handleUnpublish = () => {
    if (!checklist) return;

    Api.admin.checklists.unpublish(checklist.checklist_id).then((res) => {
      if (res.status !== 'OK') return;
      sessionStorage.setItem('message', 'Checklist has been unpublished');
      window.location.reload();
    });
  };

  const renderChecklistItems = () => {
    if (items.length === 0 && !editableItem) {
      return (
        <div className="checklist-items-placeholder">
          <span>No checklist items added yet</span>
          <Dropdown buttonContent={(
            <>
              Add New
              <FiPlus />
            </>
          )}
          >
            <DropdownItem onClick={handleAddChecklistItem}>Checklist Item</DropdownItem>
            <DropdownItem onClick={handleAddChecklistHeading}>Checklist Heading</DropdownItem>
          </Dropdown>
        </div>
      );
    }

    return (
      <div className="checklist-items">
        { items.length > 0 && (
          <div className="checklist-items-tree">
            <SortableTree
              treeData={items}
              onChange={setItems}
              isVirtualized={false}
              scaffoldBlockPxWidth={10}
              rowHeight={40}
              getNodeKey={defaultGetNodeKey}
              // eslint-disable-next-line
              nodeContentRenderer={({
                connectDragPreview,
                connectDragSource,
                isDragging,
                canDrop,
                canDrag,
                node,
                draggedNode,
                className,
                style,
                didDrop,
                path,
                ...otherProps
              }) => {
                let handle;
                if (canDrag) {
                  handle = connectDragSource(<div className="drag-handle"><GrDrag /></div>, {
                    dropEffect: 'copy',
                  });
                }

                const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
                const isLandingPadActive = !didDrop && isDragging;

                return (
                  <div style={{ height: '100%' }} {...otherProps}>
                    <div className="rst__rowWrapper">
                      {connectDragPreview(
                        <div
                          className={classnames(
                            'rst__row',
                            isLandingPadActive && 'rst__rowLandingPad',
                            isLandingPadActive && !canDrop && 'rst__rowCancelPad',
                            className,
                          )}
                          style={{
                            opacity: isDraggedDescendant ? 0.5 : 1,
                            ...style,
                          }}
                        >
                          {handle}
                          <div className="checklist-item">
                            <p>
                              {node.text}
                              { node.heading && (
                                <span className="heading">Heading</span>
                              )}
                            </p>
                            { node.tip_ids.length > 0 && (
                              <span className="tips-count">
                                {node.tip_ids.length}
                                {' '}
                                Tip
                                {node.tip_ids.length === 1 ? '' : 's'}
                                {' '}
                                Associated
                              </span>
                            ) }
                            <button type="button" className="edit-button" onClick={() => handleEditChecklistItem(node, path)}>
                              <FiEdit3 />
                            </button>
                            <button
                              type="button"
                              className="delete-button"
                              onClick={() => {
                                setItems((treeData) => removeNodeAtPath({
                                  treeData,
                                  path,
                                  getNodeKey: defaultGetNodeKey,
                                }));
                              }}
                            >
                              <FiTrash />
                            </button>
                          </div>
                        </div>,
                      )}
                    </div>
                  </div>
                );
              }}
            />
          </div>
        )}

        {items.length > 0 && Boolean(editableItem) && (
          <div className="separator" />
        )}

        { Boolean(editableItem) && (
          <div className="checklist-editable-item row">
            <div className={editableItem.heading ? 'col-sm-10' : 'col-sm-6'}>
              <div className="form-group">
                <label>{ editableItem.heading ? 'Heading Name' : 'Checklist Item Name' }</label>
                <input
                  type="text"
                  className="form-control"
                  value={editableItem.text}
                  onChange={(e) => handleChecklistItemChange('text', e.target.value)}
                />
              </div>
            </div>
            { !editableItem.heading && (
              <div className="col-sm-4">
                <div className="form-group">
                  <div className="label-row">
                    <label>Associated Tips</label>
                    <button type="button" className="create-button" onClick={() => setTipSidebarVisible(true)}>
                      Create New
                      <FiPlus />
                    </button>
                  </div>
                  <Select
                    value=""
                    options={tips.map((tip) => ({
                      label: (
                        <>
                          <span style={{ flex: 1 }}>{tip.name}</span>
                          <button
                            type="button"
                            className="category-edit-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              setTipSidebarVisible(true);
                              setEditableTip(tip);
                            }}
                          >
                            <FiEdit3 />
                          </button>
                        </>
                      ),
                      value: tip.id,
                    }))}
                    onChange={(tip) => handleChecklistItemChange('tip_ids', [...editableItem.tip_ids, tip.value])}
                  />
                </div>

                <div className="select-list-items">
                  { editableItem.tip_ids.map((tip) => (
                    <div className="list-item">
                      <span>{tips.find((t) => t.id === tip)?.name}</span>
                      <button
                        type="button"
                        className="button delete-button"
                        onClick={() => handleChecklistItemChange('tip_ids', editableItem.tip_ids.filter((c) => c !== tip))}
                      >
                        <FiTrash />
                      </button>
                    </div>
                  )) }
                </div>
              </div>
            )}

            <div className="col-sm-2 checklist-item-buttons">
              <button type="button" className="button success" onClick={handleSaveChecklistItem}>
                Save
              </button>
              <button
                type="button"
                className="button danger"
                onClick={() => setEditableItem(null)}
              >
                <FiTrash />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`admin-page react-admin-checklists-edit ${(categorySidebarVisible || tipSidebarVisible) ? 'sidebar-open' : ''}`}>
      <div className="header">
        <h2>
          <span
            className="back"
            onClick={() => {
              // eslint-disable-next-line
            history.back();
            }}
          >
            &lt;
          </span>
          <a href="/admin/checklists" className="back">Checklist</a>
          {' '}
          /
          {' '}
          { checklist && `${checklist.name} / ` }
          { checklist ? 'Edit' : 'Add New' }
          { published ? (
            <span className="tag published">Published</span>
          ) : (
            <span className="tag draft">Draft</span>
          ) }
        </h2>
        <button type="button" className="button" onClick={handleUpdate} disabled={!data.name}>
          { checklist ? 'Update' : 'Create' }
        </button>
        <button type="button" className="button" onClick={handlePublish}>
          Publish
          <FiUpload />
        </button>
        { published && (
          <button type="button" className="button danger" onClick={handleUnpublish}>
            Unpublish
            <FiArrowLeft />
          </button>
        )}
        { checklist && (
          <>
            <div className="separator" />
            <a type="button" className="button preview" href={`/admin/checklists/${checklist.checklist_id}`}>
              Preview
              <FiEye />
            </a>
          </>
        )}
      </div>
      <div className="form">
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={data.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <div
            ref={description}
            className="form-control js-wysihtml5"
          />
        </div>

        <div className="row">
          <div className="col-sm-4">
            <div className="form-group">
              <div className="label-row">
                <label>Categories</label>
                <button type="button" className="create-button" onClick={() => setCategorySidebarVisible(true)}>
                  Create New
                  <FiPlus />
                </button>
              </div>
              <Select
                value=""
                options={categories.map((cat) => ({
                  label: (
                    <>
                      <span style={{ flex: 1 }}>{cat.name}</span>
                      <button
                        type="button"
                        className="category-edit-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCategorySidebarVisible(true);
                          setEditableCategory(cat);
                        }}
                      >
                        <FiEdit3 />
                      </button>
                    </>
                  ),
                  value: cat.id,
                }))}
                onChange={(cat) => handleChange('category_ids', [...data.category_ids, cat.value])}
              />
            </div>

            <div className="select-list-items">
              { data.category_ids.map((cat) => (
                <div className="list-item">
                  <span>{categories.find((c) => c.id === cat)?.name}</span>
                  <button
                    type="button"
                    className="button delete-button"
                    onClick={() => handleChange('category_ids', data.category_ids.filter((c) => c !== cat))}
                  >
                    <FiTrash />
                  </button>
                </div>
              )) }
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label>Related Checklists</label>
              <Select
                value=""
                options={checklists.filter((c) => c.checklist_id).map((c) => ({
                  label: c.name_and_categories,
                  value: c.checklist_id,
                }))}
                onChange={(c) => handleChange('checklist_ids', [...data.checklist_ids, c.value])}
              />
            </div>
            <div className="select-list-items">
              { data.checklist_ids.map((ci) => (
                <div className="list-item">
                  <span>{checklists.find((c) => c.checklist_id === ci)?.name_and_categories}</span>
                  <button
                    type="button"
                    className="button delete-button"
                    onClick={() => handleChange('checklist_ids', data.checklist_ids.filter((c) => c !== ci))}
                  >
                    <FiTrash />
                  </button>
                </div>
              )) }
            </div>
          </div>

          <div className="col-sm-4">
            <div className="form-group">
              <label>Related Forms</label>
              <Select
                value=""
                options={forms.filter((f) => f.template_id).map((form) => ({
                  label: form.name_and_categories,
                  value: form.template_id,
                }))}
                onChange={(form) => handleChange('template_ids', [...data.template_ids, form.value])}
              />
            </div>
            <div className="select-list-items">
              { data.template_ids.map((form) => (
                <div className="list-item">
                  <span>{forms.find((c) => c.template_id === form)?.name_and_categories}</span>
                  <button
                    type="button"
                    className="button delete-button"
                    onClick={() => handleChange('template_ids', data.template_ids.filter((c) => c !== form))}
                  >
                    <FiTrash />
                  </button>
                </div>
              )) }
            </div>
          </div>
        </div>

        <div className="checklist-items-header">
          <div className="switch">
            <span className={type === 'checklist' ? 'active' : ''}>Checklist Items</span>
            <EdaSwitch checked={type === 'form'} onChange={handleSwitch} />
            <span className={type === 'form' ? 'active' : ''}>Upload Form File</span>
          </div>
          { items.length > 0 && (
            <Dropdown buttonContent={(
              <>
                Add New
                <FiPlus />
              </>
            )}
            >
              <DropdownItem onClick={handleAddChecklistItem}>Checklist Item</DropdownItem>
              <DropdownItem onClick={handleAddChecklistHeading}>Checklist Heading</DropdownItem>
            </Dropdown>
          )}
        </div>

        { type === 'checklist' && renderChecklistItems()}
      </div>

      { categorySidebarVisible && (
        <CategorySidebar
          onClose={() => { setCategorySidebarVisible(false); setEditableCategory(null); }}
          category={editableCategory}
          onAdded={handleCategoryUpdate}
          onDeleted={handleCategoryUpdate}
          onUpdated={handleCategoryUpdate}
        />
      )}

      { tipSidebarVisible && (
        <TipsSidebar
          onClose={() => { setTipSidebarVisible(false); setEditableTip(null); }}
          tip={editableTip}
          onAdded={handleTipUpdate}
          onDeleted={handleTipUpdate}
          onUpdated={handleTipUpdate}
        />
      )}
    </div>
  );
}
