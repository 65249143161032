import React, { useMemo, useState, useEffect } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import Menu, { MenuItem } from 'rc-menu';
import Item from './Item';
import Header from './Header';
import Hero from './Hero';
import Filters from './Filters';

const typeOptions = [
  {
    value: 'Video',
    label: 'Videos',
  }, {
    value: 'Post',
    label: 'Posts',
  }, {
    value: 'Article',
    label: 'Articles',
  }, {
    value: 'Audio',
    label: 'Audio',
  }, {
    value: 'Document',
    label: 'Documents',
  }, {
    value: 'Ebook',
    label: 'Ebooks',
  }];

function Category({
  name, items, currentType, isAdmin, isAcademy = false, subscription = false,
}) {
  const hasItems = !items.find((item) => currentType === item.type) && currentType !== '';
  if (hasItems) return null;
  return (
    <div className="section">
      <div className="section-header">
        <h3>{name || ''}</h3>
      </div>
      <div className="items">
        {items?.map((item) => (
          (currentType === item.type || currentType === '') && <Item data={item} key={item.id} isAdmin={isAdmin} isAcademy={isAcademy} subscription={subscription} />
        ))}
      </div>
    </div>
  );
}

export default function SearchResults({
  tags, topics, currentUser, results, topicId, tagId, query, isAdmin, academySubscription, subscription,
}) {
  const [typeDropdownVisible, setTypeDropdownVisible] = useState(false);
  const [currentType, setCurrentType] = useState('');
  const types = useMemo(() => {
    if (!results) return [];
    return [...new Set(results.map((item) => item.type))];
  });

  useEffect(
    () => {
      const listener = (event) => {
        if (event.target.closest('.lc-menu, .lc-submenu')) return;
        setTypeDropdownVisible(false);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    [],
  );

  const handleSelectType = ({ key }) => {
    if (!key) return;
    setCurrentType(key === 'all' ? '' : key);
    setTypeDropdownVisible(false);
  };

  const searchResults = useMemo(() => {
    if (!results) return {};
    const map = {};
    results.forEach((item) => {
      item.media_category_ids?.forEach((cat) => {
        map[cat] = map[cat] || [];
        map[cat].push(item);
      });
    });
    return map;
  }, [results]);

  return (
    <div className="react-lc-page lc-search-results">
      <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} />
      <Hero
        title="eDiscovery Academy"
        subtitle="Take your professional skills to the next level with us"
      >
        <Filters
          tags={tags}
          topics={topics}
          initialTagId={tagId}
          initialTopicId={topicId}
          initialQuery={query}
          type={currentType}
        />
      </Hero>
      { results?.length > 0 && (
        <div className="results-count">
          {results.length}
          {' '}
          result
          { results.length > 1 ? 's' : ''}
          , Dashboard / Search Result
        </div>
      )}

      { results?.length > 0 && (
        <div className="found-count">
          We found
          {' '}
          {results.length}
          {' '}
          result
          { results.length > 1 ? 's' : ''}

          <div className="lc-select">
            <div
              style={{ width: 160 }}
              className="search-input"
              onClick={() => setTypeDropdownVisible(true)}
            >
              { typeOptions.find((t) => t.value === currentType)?.label || 'All Content' }
              <FiChevronDown />
            </div>
            { typeDropdownVisible && (
              <Menu
                className="lc-menu type-menu"
                onSelect={handleSelectType}
              >
                <MenuItem key="all"> Content Type </MenuItem>
                { typeOptions.map((t) => (
                  <MenuItem
                    disabled={!types?.find((type) => type === t.value)}
                    key={t.value || 'all'}
                  >
                    {t.label}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </div>
        </div>
      )}

      { results?.length === 0 && (
        <div className="empty">
          There are no results found.
          <br />
          Please try a different search.
          <div className="empty-image" />
        </div>
      )}

      { Boolean(searchResults) && Object.keys(searchResults)?.map((categoryId) => (
        <Category
          name={topics?.find((t) => t.id.toString() === categoryId)?.name}
          items={searchResults[categoryId]}
          currentType={currentType}
          isAdmin={isAdmin}
          isAcademy={academySubscription}
          subscription={subscription}
        />
      ))}
    </div>
  );
}
