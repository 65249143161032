import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

class DistrictRulesNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateSearchValue: '',
      districtSearchValue: '',
    };
  }

  componentDidMount() {
    const _stateFilter = new URLSearchParams(window.location.search).get('districtState')
      ? new URLSearchParams(window.location.search).get('districtState') : '';
    const _districtFilter = new URLSearchParams(window.location.search).get('district')
      ? new URLSearchParams(window.location.search).get('district') : '';

    this._setStateSearchValue({ value: _stateFilter, label: _stateFilter });
    this._setDistrictSearchValue({ value: _districtFilter, label: _districtFilter });
  }

  _setStateSearchValue = (newValue) => {
    const { setDistrictStateFilter, selectDistrict } = this.props;
    selectDistrict('');
    if (newValue === null) return setDistrictStateFilter('');
    this.setState({ stateSearchValue: newValue.label, districtSearchValue: '' });
    return setDistrictStateFilter(newValue.label);
  };

  _setDistrictSearchValue = (newValue) => {
    const { selectDistrict } = this.props;
    if (newValue === null) return selectDistrict('');
    this.setState({ districtSearchValue: newValue.label });
    return selectDistrict(newValue.label);
  };

  _buildDistrictOptions = () => {
    const { districtStates, districtStateFilter } = this.props;
    const _state = districtStates.find((state) => state.name === districtStateFilter);
    if (_state === undefined) return '';
    return _state.district_courts.map((court) => ({ value: court.name, label: court.name }));
  };

  render() {
    const { stateSearchValue, districtSearchValue } = this.state;
    const { allStates } = this.props;

    const customStyles = {
      option: (styles, state) => ({
        ...styles,
        marginTop: '-1rem',
        color: state.isSelected ? '#FFF' : '#000E57',
        backgroundColor: state.isSelected ? 'rgba(0, 14, 87, 0.8)' : styles.color,
        '&:hover': {
          color: '#FFF',
          backgroundColor: 'rgba(0, 14, 87, 0.6)',
        },
      }),
      control: (styles, state) => ({
        ...styles,
        boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(0, 14, 87, 0.6)' : 0,
        width: '20rem',
        borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
        '&:hover': {
          borderColor: state.isFocused ? 'rgba(0, 14, 87, 0.6)' : '#CED4DA',
        },
        marginBottom: '1rem',
      }),
      container: (styles) => ({
        ...styles,
        width: '20rem',
      }),
      menu: (styles) => ({
        ...styles,
        marginTop: '0',
      }),
      singleValue: (styles) => ({
        ...styles,
        color: '#000E57',
      }),
    };

    return (
      <div className="state-rules-navigator">
        <div className="selector district-selector">
          <Select
            placeholder="Filter by State"
            isSearchable={false}
            value={stateSearchValue === '' ? '' : { value: stateSearchValue, label: stateSearchValue }}
            onChange={this._setStateSearchValue}
            options={allStates.map((state) => ({ value: state.name, label: state.name }))}
            styles={customStyles}
          />
          <Select
            placeholder="Filter by District"
            isSearchable={false}
            value={districtSearchValue === '' ? '' : { value: districtSearchValue, label: districtSearchValue }}
            onChange={this._setDistrictSearchValue}
            options={this._buildDistrictOptions()}
            styles={customStyles}
          />
        </div>
      </div>
    );
  }
}

DistrictRulesNavigator.propTypes = {
  districtStates: PropTypes.array.isRequired,
  setDistrictStateFilter: PropTypes.func.isRequired,
  selectDistrict: PropTypes.func.isRequired,
  allStates: PropTypes.array.isRequired,
  districtStateFilter: PropTypes.string.isRequired,
};

export default DistrictRulesNavigator;
