// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="login-modal new-modal"
  data-testid="modal"
  onClick={[Function]}
>
  <div
    className="modal-dialog modal-md"
  >
    <div
      className="modal-content"
    >
      <div
        className="circle"
      />
      <div
        className="modal-header"
      >
        <div
          className="modal-title"
        >
          <img
            alt=""
            className="react-header-logo"
            src="/logo.svg"
          />
        </div>
      </div>
      <div
        className="modal-body"
      >
        <p>
          Thank you for visiting eDiscovery Academy. Academy is not currently part of your subscription. To gain access, click subscribe or contact  
          <a
            href="mailto: sales@ediscoveryassistant.com?subject=Access to eDiscovery Academy"
          >
            sales@ediscoveryassistant.com
          </a>
            for assistance in upgrading your subscription.
        </p>
        <div
          className="button-row"
        >
          <button
            className="modal-button"
          >
            <a
              href="/account/edit?tab=billingInfo"
              style={
                Object {
                  "color": "white",
                  "textDecoration": "none",
                }
              }
              target="_blank"
            >
              Subscribe
            </a>
          </button>
          <button
            className="modal-button modal-button-outline"
          >
            <a
              href="mailto: sales@ediscoveryassistant.com?subject=Access to eDiscovery Academy"
              style={
                Object {
                  "color": "#343B64",
                  "textDecoration": "none",
                }
              }
            >
              Contact
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
`;
