import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ClipLoader } from 'react-spinners';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import { GoSearch } from 'react-icons/go';
import TagList from '../../tags/TagList';

export default function DistrictRules({
  districtStates, favorites, toggleRuleBookmark, districtFilter, loading,
}) {
  const _state = districtStates === null ? undefined : districtStates.find((state) => state.district_courts.some(
    (district) => district.name === districtFilter,
  ));
  const selectedDistrict = _state !== undefined ? _state.district_courts
    .find((district) => district.name === districtFilter) : null;

  const [issuesQuery, setIssuesQuery] = useState('');
  const isMatchQuery = (rule) => issuesQuery === '' || rule.published_revision.tag_list.find((tag) => tag.toLowerCase().includes(issuesQuery.toLowerCase()));

  const renderSearch = () => (
    <div className="case-search">
      <input type="text" placeholder="Search Issues, try: Sanctions" onChange={(e) => setIssuesQuery(e.target.value)} value={issuesQuery} />
      <GoSearch />
      <span style={{ flex: 1 }} />
    </div>
  );

  return (
    <div className="district-rules">
      {
        districtStates === null ? (
          <div className="select-state">
            <div className="empty-description">
              <span>There are no district court rules displayed.</span>
              <span>Select a filter on the left to start.</span>
            </div>
            <div className="empty-image" />
          </div>
        ) : loading ? (
          <div className="loader">
            <ClipLoader color="rgba(0, 14, 87, 1)" />
            <div className="name">Loading</div>
          </div>
        ) : districtStates.map((state) => state.district_courts.map((court) => selectedDistrict === court
            && (
            <div className="district-rule-list" key={court.id}>
              <div className="title">
                { court.name }
                { renderSearch() }
              </div>
              <div className="summary">{ court.description }</div>
              <div className="results">
                {
                  court.district_rules.map((rule) => !Array.isArray(rule.published_revision) && rule.published_revision.published && isMatchQuery(rule) && (
                  <a
                    className="result"
                    key={rule.id}
                    href={`/district_rules/${rule.id}`}
                  >
                    <div className="top">
                      <div className="result-number">
                        { rule.published_revision.number }
                      </div>
                      {favorites.rules !== undefined && favorites.rules.some(
                        (favoriteRule) => favoriteRule.id === rule.id,
                      ) ? (
                        <FaBookmark onClick={(e) => toggleRuleBookmark(e, rule.published_revision)} />
                        ) : (
                          <FaRegBookmark
                            className="initially-hidden"
                            onClick={(e) => toggleRuleBookmark(e, rule.published_revision)}
                          />
                        )}
                    </div>
                    <TagList className="result-tags" tags={rule.published_revision.tag_list} />
                  </a>
                  ))
}
              </div>
            </div>
            )))
}
    </div>
  );
}

DistrictRules.propTypes = {
  districtStates: PropTypes.array,
  toggleRuleBookmark: PropTypes.func.isRequired,
  favorites: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

DistrictRules.defaultProps = {
  districtStates: null,
};
