import React from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Item from './Item';
import Header from './Header';
import Hero from './Hero';
import Filters from './Filters';
import { renderTotalPages } from '../../services/pages';

export default function Topic({
  currentUser, topic, items, totalPages, currentPage, totalCount, isAdmin, tags, academySubscription, subscription,
}) {
  return (
    <div className="react-lc-page lc-topic">
      <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} />
      <Hero
        title={topic?.name}
        subtitle={topic?.description || 'Take your professional skills to the next level with us'}
      >
        <Filters
          tags={tags}
          initialTopicId={topic.id}
          isModule
        />
      </Hero>
      { items?.length > 0 && (
        <div className="results-count">
          { (currentPage - 1) * 25 + 1 }
          {' - '}
          { Math.min(currentPage * 25, totalCount) }
          {' of '}
          {totalCount}
          {' '}
          result
          { totalCount > 1 ? 's' : ''}
          , Dashboard /
          {' '}
          {topic.name}
        </div>
      )}

      <div className="section">
        { items.length === 0 && (
          <div className="empty">
            No results found!
          </div>
        )}
        <div className="items">
          { items?.map((item) => (
            <Item data={item} topicId={topic.id} key={item.id} isAdmin={isAdmin} isAcademy={academySubscription} subscription={subscription} />
          ))}
        </div>

        <div className="pagination">
          <a className={`page arrow ${currentPage <= 1 && 'disabled'}`} href={`${window.location.pathname}?page=${currentPage - 1}`}>
            <FiChevronLeft />
          </a>
          {renderTotalPages(totalPages, currentPage)}
          <a className={`page arrow ${currentPage >= totalPages && 'disabled'}`} href={`${window.location.pathname}?page=${currentPage + 1}`}>
            <FiChevronRight />
          </a>
        </div>
      </div>
    </div>
  );
}
