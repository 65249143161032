import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FadeLoader } from 'react-spinners';
import { GoSearch } from 'react-icons/go';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import {
  FaCheckSquare, FaRegSquare, FaCalendarAlt,
} from 'react-icons/all';
import TagSection from './src/TagSection';
import Results from './src/Results';
import JurisdictionSection from './src/JurisdictionSection';
import JurisdictionFilter from './src/JurisdictionFilter';
import TagFilter from './src/TagFilter';
import {
  appendValue, formatValues, getValues, removeValue,
} from '../../services/booleanSearch';
import VideoModal from '../video/VideoModal';
import TutorialIcon from '../../../assets/images/tutorial.svg';
import SearchFailedModal from '../search_modal/SearchFailedModal';

class Search extends React.Component {
  constructor(props) {
    super(props);
    const {
      tags,
      courtIds,
      jurisdictions,
      courtCounts,
      caseDigests,
      slugs,
      query,
      page,
      sortBy,
      resultsPerPage,
      judge,
      caseTitle,
      startDate,
      endDate,
      includeUnpublishedDecisions,
      type,
      tagIdsQuery,
      tutorialVideo,
      error,
    } = this.props;

    localStorage.setItem('CASE_DIGEST_SEARCH_RESULTS', JSON.stringify({
      slugs,
      query,
      courtIds,
      startDate,
      endDate,
      sortBy,
      resultsPerPage,
      page,
      judge,
      caseTitle,
      type,
      tagIdsQuery,
      search_mode: 'case_law',
    }));

    this.state = {
      selectedJurisdictions: {
        state: [],
        federal: [],
        admin: [],
      },
    };

    if (courtIds != null) {
      courtIds.forEach((courtId) => {
        const adminCourt = jurisdictions.Administrative.find((court) => court[1] === courtId);
        if (adminCourt !== undefined) {
          this._selectJurisdiction(adminCourt, 'admin');
          return;
        }
        const stateCourt = jurisdictions.State.find((court) => court[1] === courtId);
        if (stateCourt !== undefined) {
          this._selectJurisdiction(stateCourt, 'state');
          return;
        }
        const topLevelFederalCourt = jurisdictions.Federal.find((circuit) => circuit.courts.find((court) => court.id === courtId));
        const federalCourt = topLevelFederalCourt && topLevelFederalCourt.courts.find(((court) => court.id === courtId));
        if (federalCourt !== undefined) {
          this._selectJurisdiction(federalCourt, 'federal');
        }
      });
    }

    const tagQuery = formatValues(tagIdsQuery || '', (value) => {
      const tag = tags.find((t) => t.id === Number(value));
      return tag ? tag.name : '';
    }).trim();

    let tutorialVideoObj = null;
    if (tutorialVideo) {
      const url = new URL(tutorialVideo);
      let domain;
      if (url.hostname.includes('youtube')) domain = 'youtube';
      else if (url.hostname.includes('vimeo')) domain = 'vimeo';
      else if (url.hostname.includes('wistia')) domain = 'wistia';
      tutorialVideoObj = { domain, url: tutorialVideo };
    }

    this.state = {
      searchFailedModalOpen: Boolean(error),
      selectedJurisdictions: this.state.selectedJurisdictions,
      tagSectionOpen: false,
      loadingModalOpen: false,
      jurisdictionSectionOpen: false,
      tutorialModalOpen: false,
      noResultsModalOpen: window.location.href.includes('?') && caseDigests.length === 0,
      q: decodeURIComponent(query || ''),
      judge: decodeURIComponent(judge || ''),
      caseTitle: decodeURIComponent(caseTitle || ''),
      startDate: startDate ? Date.parse(startDate) : '',
      endDate: endDate ? Date.parse(endDate) : '',
      sortBy,
      resultsPerPage,
      results: {
        case_digests: caseDigests,
        court_counts: courtCounts,
        query,
        current_page: page,
      },
      includeUnpublishedDecisions,
      type: type || 'issue',
      tagQuery,
      tutorialVideo: tutorialVideoObj,
      searchOpened: false,
    };

    this.issueTagsRef = React.createRef();
  }

  componentDidMount() {
    $('.search-datepicker').on('keydown', this.handleDatepickerKeydown);
  }

  componentWillUnmount() {
    $('.search-datepicker').off('keydown', this.handleDatepickerKeydown);
  }

  handleDatepickerKeydown = (e) => {
    if (e.keyCode === 13) {
      this._search(1);
    }
  };

  handleSort = (sortBy) => {
    this.setState({ sortBy }, () => {
      this._search(1);
    });
  };

  handlePerPage = (resultsPerPage) => {
    this.setState({ resultsPerPage }, () => {
      this._search(1);
    });
  };

  setTagQuery = (value) => {
    this.setState({ tagQuery: value });
  };

  _search = (page) => {
    const { tags } = this.props;
    const { selectedJurisdictions, tagQuery } = this.state;

    const courtIds = [
      ...selectedJurisdictions.state.map((d) => d[1]),
      ...selectedJurisdictions.state.map((d) => d[3]),
      ...selectedJurisdictions.admin.map((d) => d[1]),
      ...selectedJurisdictions.federal.map((d) => d.id),
    ];

    const tagIdsQuery = formatValues(tagQuery, (value) => {
      const tag = tags.find((t) => t.name.toLowerCase() === value.toLowerCase());
      return tag ? tag.id : 0;
    });

    this.setState({ loadingModalOpen: true });

    const data = {
      q: this.state?.q?.trim(),
      court_ids: courtIds,
      daterange: this.state.startDate && this.state.endDate
        ? 'between' : this.state.startDate ? 'after' : this.state.endDate ? 'before' : '',
      start_date: this.state.startDate ? moment(this.state.startDate).format('MM/DD/YYYY') : '',
      end_date: this.state.endDate ? moment(this.state.endDate).format('MM/DD/YYYY') : '',
      sort_by: this.state.sortBy || 'des_decision_date',
      page,
      results_per_page: this.state.resultsPerPage || 25,
      judge: this.state?.judge?.trim(),
      case_title: this.state?.caseTitle?.trim(),
      include_unpublished: this.state.includeUnpublishedDecisions.toString(),
      type: this.state.type,
      tag_ids_query: tagIdsQuery,
    };

    const _params = new URLSearchParams();
    Object.keys(data).forEach((prop) => {
      if (!data[prop] || data[prop].length === 0) return;
      _params.set(prop, data[prop]);
    });

    document.location = `case_law?${_params.toString()}`;
  };

  _setQuery = (e) => {
    this.setState({ q: e.target.value });
  };

  _clearQuery = () => {
    const { query } = this.props;
    this.setState({ q: '' }, () => query && this._search(1));
  };

  _addBooleanToQuery = (boolString) => {
    const { q } = this.state;
    this.setState({ q: q.concat(boolString) });
  };

  _addBooleanToTitle = (boolString) => {
    const { caseTitle } = this.state;
    this.setState({ caseTitle: caseTitle.concat(boolString) });
  };

  _setJudge = (e) => {
    this.setState({ judge: e.target.value });
  };

  _clearJudge = () => {
    const { judge } = this.props;
    this.setState({ judge: '' }, () => judge && this._search(1));
  };

  _setCaseTitle = (e) => {
    this.setState({ caseTitle: e.target.value });
  };

  _clearCaseTitle = () => {
    const { caseTitle } = this.props;
    this.setState({ caseTitle: '' }, () => caseTitle && this._search(1));
  };

  _selectTag = (tag) => {
    this.issueTagsRef.current.focus();
    this.setTagQuery(appendValue(this.state.tagQuery, tag.name, this.issueTagsRef.current.selectionStart));
  };

  _unselectTag = (tag) => {
    this.issueTagsRef.current.focus();
    this.setTagQuery(removeValue(this.state.tagQuery, tag.name));
  };

  _selectJurisdiction = (jurisdiction, type) => {
    const { selectedJurisdictions } = this.state;

    const _jurisdictionIndex = selectedJurisdictions[type].indexOf(jurisdiction);

    if (_jurisdictionIndex !== -1) {
      selectedJurisdictions[type].splice(_jurisdictionIndex, 1);
    } else {
      selectedJurisdictions[type].push(jurisdiction);
    }

    this.setState({ selectedJurisdictions });
  };

  _selectAllJurisdictions = (type) => {
    const { jurisdictions } = this.props;
    const { selectedJurisdictions } = this.state;
    if (type === 'state') {
      selectedJurisdictions.state = [];
      selectedJurisdictions.state = [...jurisdictions.State];
    }
    if (type === 'admin') {
      selectedJurisdictions.admin = [];
      selectedJurisdictions.admin = [...jurisdictions.Administrative];
    }
    if (type === 'federal') {
      selectedJurisdictions.federal = [];
      jurisdictions.Federal.forEach((circuit) => circuit.courts.forEach(
        (court) => selectedJurisdictions.federal.push(court),
      ));
    }
    this.setState({ selectedJurisdictions });
  };

  _selectJurisdictionsInCircuit = (circuit) => {
    const { selectedJurisdictions } = this.state;
    if (this._selectedJurisdictionsContainsCircuit(circuit)) {
      circuit.courts.forEach((court) => selectedJurisdictions.federal
        .splice(selectedJurisdictions.federal.indexOf(court), 1));
    } else {
      circuit.courts.forEach((court) => (!selectedJurisdictions.federal.includes(court)
        ? selectedJurisdictions.federal.push(court) : null));
    }
    this.setState({ selectedJurisdictions });
  };

  _selectedJurisdictionsContainsCircuit = (circuit) => {
    const { selectedJurisdictions } = this.state;
    return circuit.courts.every((court) => selectedJurisdictions.federal.includes(court));
  };

  _clearAllJurisdictions = (type, rerunSearch) => {
    let { selectedJurisdictions } = this.state;

    if (type) {
      selectedJurisdictions[type] = [];
    } else {
      selectedJurisdictions = { federal: [], state: [], admin: [] };
    }

    this.setState({ selectedJurisdictions }, () => rerunSearch && this._search(1));
  };

  _setStartDate = (date) => {
    this.setState({ startDate: date });
  };

  _setEndDate = (date) => {
    this.setState({ endDate: date });
  };

  _openTagSection = () => {
    this.setState({ tagSectionOpen: true, jurisdictionSectionOpen: false });
  };

  _openJurisdictionModal = () => {
    this.setState({ jurisdictionSectionOpen: true, tagSectionOpen: false });
  };

  _closeModal = () => {
    const { results } = this.state;
    this.setState({
      noResultsModalOpen: false,
      tutorialModalOpen: false,
    });
    if (results.case_digests.length !== 0) this._search(1);
  };

  _closeTagSection = () => {
    this.setState({ tagSectionOpen: false });
  };

  _closeJurisdictionSection = () => {
    this.setState({ jurisdictionSectionOpen: false });
  };

  _renderTagSection = () => {
    const { tags } = this.props;
    const { tagQuery } = this.state;
    return (
      <TagSection
        tags={tags}
        closeSection={this._closeTagSection}
        selectTag={this._selectTag}
        unselectTag={this._unselectTag}
        selectedTags={getValues(tagQuery)}
      />
    );
  };

  _renderJurisdictionSection = () => {
    const { jurisdictions } = this.props;
    return (
      <JurisdictionSection
        closeSection={this._closeJurisdictionSection}
        selectJurisdiction={this._selectJurisdiction}
        selectJurisdictionsInCircuit={this._selectJurisdictionsInCircuit}
        selectedJurisdictionsContainsCircuit={this._selectedJurisdictionsContainsCircuit}
        selectedJurisdictions={this.state.selectedJurisdictions}
        jurisdictions={jurisdictions}
        selectAllJurisdictions={this._selectAllJurisdictions}
        clearAllJurisdictions={this._clearAllJurisdictions}
      />
    );
  };

  _renderLoadingModal = () => (
    <div className="loading-modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="margin">
            <FadeLoader color="rgba(0, 14, 87, 1)" className="fade-loader" />
          </div>
          <div className="name">Loading</div>
        </div>
      </div>
    </div>
  );

  _renderNoResultsModal = () => (
    <div className="no-results-modal">
      <div className="modal-dialog modal-sm">
        <div className="modal-content">
          <div className="name">No Results</div>
          <button
            type="button"
            className="button-clear pull-right"
            onClick={this._closeModal}
          >
            ✕
          </button>
        </div>
      </div>
    </div>
  );

  _renderFederalJurisdictionTags = () => {
    const { jurisdictions } = this.props;

    const { selectedJurisdictions } = this.state;

    let federalCourtCount = 0;
    jurisdictions.Federal.forEach((circuit) => { federalCourtCount += circuit.courts.length; });
    if (selectedJurisdictions.federal.length === federalCourtCount) {
      return (
        <li>
          <a href="#" className="tag active" onClick={() => this._clearAllJurisdictions('federal')}>
            All Federal&nbsp;&nbsp;&nbsp;✕
          </a>
        </li>
      );
    }
    const circuitCourtsMap = new Map();
    jurisdictions.Federal.forEach((circuit) => circuitCourtsMap.set(circuit.id, []));
    selectedJurisdictions.federal.forEach((court) => {
      circuitCourtsMap.get(court.class.toString()).push(court);
    });

    const renderList = [];
    circuitCourtsMap.forEach((courts, circuitId) => {
      const circuit = jurisdictions.Federal.find((c) => c.id === circuitId);
      if (courts.length === circuit.courts.length) {
        renderList.push((
          <li key={circuit.id}>
            <a href="#" className="tag active" onClick={() => this._selectJurisdictionsInCircuit(circuit)}>
              {circuit.name}
&nbsp;&nbsp;&nbsp;✕
            </a>
          </li>
        ));
      } else {
        courts.forEach((court) => renderList.push((
          <li key={court.id}>
            <a href="#" className="tag active" onClick={() => this._selectJurisdiction(court, 'federal', true)}>
              {court.name}
              &nbsp;&nbsp;&nbsp;
              ✕
            </a>
          </li>
        )));
      }
    });

    return (renderList);
  };

  _renderTutorialModal() {
    const { tutorialVideo } = this.state;
    return <VideoModal video={tutorialVideo} closeModal={this._closeModal} />;
  }

  isSearchAvailable() {
    const {
      type, q, startDate, endDate, judge, caseTitle, selectedJurisdictions, tagQuery,
    } = this.state;
    const selectedTags = getValues(tagQuery);
    if (type === 'issue' && selectedTags.length === 0) return false;
    if (type === 'term' && q.length === 0) return false;
    if (type === 'advanced'
        && selectedTags.length === 0
        && q.length === 0
        && !startDate && !endDate
        && !judge
        && !caseTitle
        && selectedJurisdictions.federal.length === 0 && selectedJurisdictions.admin.length === 0 && selectedJurisdictions.state.length === 0
    ) return false;
    return true;
  }

  renderIssueTags({ showTitle = false } = {}) {
    const { tags, clearDashboardTags } = this.props;
    const { tagQuery } = this.state;
    return (
      <>
        <div className="flex-container">
          { showTitle && <p className="search-field-name">Issue Tags</p> }
          <TagFilter
            ref={this.issueTagsRef}
            tags={tags}
            openModal={this._openTagSection}
            setSearchQuery={this.setTagQuery}
            searchQuery={tagQuery}
            onSubmit={() => this._search(1)}
            clearDashboardTags={clearDashboardTags}
          />
        </div>

        <div className={`include-unpublished-label${this.state.includeUnpublishedDecisions === true ? ' selected' : ''}`}>
          {this.state.includeUnpublishedDecisions === true
            ? <FaCheckSquare onClick={() => this.setState({ includeUnpublishedDecisions: false })} />
            : <FaRegSquare onClick={() => this.setState({ includeUnpublishedDecisions: true })} />}
          Include Unpublished Decisions
        </div>
      </>
    );
  }

  renderHighlightTerms() {
    const { q } = this.state;

    return (
      <div className="flex-container">
        <div className="case-search">
          <input type="text" placeholder="Search Terms" onChange={this._setQuery} value={q} />
          <GoSearch />
          <span style={{ flex: 1 }} />
          { q?.length > 0
            && (
            <span className="clear-tags" onClick={() => this._clearQuery()}>
              ✕
            </span>
            ) }
        </div>
        <div className="react-search-tips">
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('AND ')}
          >
            AND
          </button>
            &nbsp;
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('OR ')}
          >
            OR
          </button>
            &nbsp;
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('NOT ')}
          >
            NOT
          </button>
            &nbsp;
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('()')}
          >
            ( )
          </button>
            &nbsp;
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('\'\'')}
          >
            &ldquo; &rdquo;
          </button>
            &nbsp;
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('?')}
          >
            ?
          </button>
            &nbsp;
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('* ')}
          >
            *
          </button>
            &nbsp;
          <button
            type="button"
            className="btn btn-flat tag"
            onClick={() => this._addBooleanToQuery('w/')}
          >
            w/
          </button>

        </div>
      </div>
    );
  }

  renderJurisdictionFilter() {
    const { jurisdictions, courtIds } = this.props;
    const { results } = this.state;

    return (
      <JurisdictionFilter
        search={this._search}
        resultsExist={results.case_digests.length !== 0}
        jurisdictions={jurisdictions}
        selectedJurisdictions={this.state.selectedJurisdictions}
        selectJurisdiction={this._selectJurisdiction}
        openModal={this._openJurisdictionModal}
        clearAllJurisdictions={(type) => this._clearAllJurisdictions(type, courtIds.length && !type)}
        renderFederalJurisdictionTags={this._renderFederalJurisdictionTags}
      />
    );
  }

  renderDatePickers() {
    return (
      <div className="flex-container">
        <div className="search-dates">
          <div className="datepickers">
            <div>
              <DatePicker
                className="search-datepicker"
                selected={this.state.startDate}
                onChange={this._setStartDate}
                placeholderText="Start Date"
              />
              { this.state.startDate && <span className="clear-dates " onClick={() => this.setState({ startDate: '' })}>✕</span> }
              <FaCalendarAlt />
            </div>

            <div>
              <DatePicker
                className="search-datepicker"
                selected={this.state.endDate}
                onChange={this._setEndDate}
                placeholderText="End Date"
              />
              { this.state.endDate && <span className="clear-dates" onClick={() => this.setState({ endDate: '' })}>✕</span> }
              <FaCalendarAlt />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderJudgePicker() {
    const { judge } = this.state;

    return (
      <div className="flex-container">
        <div className="search-judge">
          <div className="search-judge-input">
            <input type="text" value={judge} onChange={this._setJudge} placeholder="Add Judge" />
            <span style={{ flex: 1 }} />
            { judge?.length > 0 && <span className="clear-tags" onClick={() => this._clearJudge()}>✕</span> }
          </div>
        </div>
      </div>
    );
  }

  renderCaseTitlePicker() {
    const { caseTitle } = this.state;

    return (
      <div className="flex-container">
        <div className="search-case-title">
          <div className="search-case-title-input">
            <input type="text" value={caseTitle} onChange={this._setCaseTitle} placeholder="Case Title" />
            <span style={{ flex: 1 }} />
            { caseTitle?.length > 0 && <span className="clear-tags" onClick={() => this._clearCaseTitle()}>✕</span> }
          </div>
          <div className="react-search-tips">
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToTitle('AND ')}
            >
              AND
            </button>
              &nbsp;
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToTitle('OR ')}
            >
              OR
            </button>
              &nbsp;
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToTitle('NOT ')}
            >
              NOT
            </button>
              &nbsp;
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToTitle('()')}
            >
              ( )
            </button>
              &nbsp;
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToTitle('\'\'')}
            >
              &ldquo; &rdquo;
            </button>
              &nbsp;
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToTitle('?')}
            >
              ?
            </button>
              &nbsp;
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToTitle('* ')}
            >
              *
            </button>
              &nbsp;
            <button
              type="button"
              className="btn btn-flat tag"
              onClick={() => this._addBooleanToQuery('w/')}
            >
              w/
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      tags, isAdmin, allResultIds, federalCourts, stateCourts, favorites, error,
    } = this.props;
    const {
      results,
      tagSectionOpen,
      loadingModalOpen,
      jurisdictionSectionOpen,
      noResultsModalOpen,
      tutorialModalOpen,
      type,
      tutorialVideo,
      searchFailedModalOpen,
      searchOpened,
    } = this.state;
    const isIssues = window.location.href.includes('issue_dashboard');

    if (!window.location.href.includes('?') && !isIssues) {
      return (
        <form
          className="react-search"
          onSubmit={(e) => {
            e.preventDefault();
            return this._search(1);
          }}
        >
          <div className="left">
            { tutorialVideo && (
            <button className="tutorial-button" onClick={() => this.setState({ tutorialModalOpen: true })}>
              Tutorial
              <img src={TutorialIcon} alt="tutorial" />
            </button>
            )}
            <div className="empty-image" />
            <div className="search-page-title">Create Your Case Law Search</div>
            <div className="search-page-subtitle">
              Start by selecting Issue Search, Term Search, or Advanced to enter multiple filters at once.
              <br />
              You can build on your search at any time.
            </div>
            <div className="react-search-content">
              <div className="steps">
                <div
                  className={`tag ${type === 'issue' && 'active'}`}
                  onClick={() => this.setState({ type: 'issue' })}
                >
                  Issue Search
                </div>
                <div
                  className={`tag ${type === 'term' && 'active'}`}
                  onClick={() => this.setState({ type: 'term' })}
                >
                  Term Search
                </div>
                <div
                  className={`tag ${type === 'advanced' && 'active'}`}
                  onClick={() => this.setState({ type: 'advanced' })}
                >
                  Advanced Search
                </div>
              </div>

              <div className="step">
                { this.renderIssueTags() }
              </div>

              { (type === 'term' || type === 'advanced') && (
              <div className="step">
                { this.renderHighlightTerms() }
              </div>
              ) }

              { type === 'advanced' && (
              <>
                <div className="step">
                  { this.renderJurisdictionFilter() }
                </div>
                <div className="step">
                  { this.renderDatePickers() }
                </div>
                <div className="step">
                  { this.renderCaseTitlePicker() }
                </div>
                <div className="step">
                  { this.renderJudgePicker() }
                </div>
              </>
              )}
            </div>

            <button
              className="next-button"
              onClick={() => this._search()}
              disabled={!this.isSearchAvailable()}
            >
              <GoSearch />
              Search
            </button>
          </div>

          { tagSectionOpen && (
          <div className="right">
            <div className="right-content">
              { this._renderTagSection() }
            </div>
          </div>
          )}
          { jurisdictionSectionOpen && (
          <div className="right">
            <div className="right-content">
              { this._renderJurisdictionSection() }
            </div>
          </div>
          )}
          { noResultsModalOpen && this._renderNoResultsModal() }
          { tutorialModalOpen && this._renderTutorialModal() }
        </form>
      );
    }

    return (
      <>
        { !isIssues && (
          <div className="case-law-header">
            {/* eslint-disable */}
            <a
              className="mobile-header-back"
              href="/case_law"
            />
            {/* eslint-enable */}
            <div className="mobile-header-cases">{ `${results.court_counts.total_count} cases found` }</div>
            <div
              className="mobile-search-open"
              onClick={() => {
                this.setState({ searchOpened: !searchOpened });
              }}
            />
          </div>
        )}
        <div className={`react-search-results ${isIssues ? 'search-issue-dashboard' : ''}`}>
          <form
            className={`search-fields ${isIssues ? 'search-fields-issue-dashboard' : ''} ${searchOpened || isIssues ? 'opened' : ''}`}
            onSubmit={(e) => {
              e.preventDefault();
              return this._search(1);
            }}
          >
            <div className={`search-fields-inner ${isIssues ? 'search-fields-inner-issue-dashboard' : ''}`}>
              <div className="search-field">
                { this.renderIssueTags({ showTitle: true }) }
              </div>

              <div className="search-fields-extra">
                <div className="search-field">
                  { this.renderHighlightTerms() }
                </div>
                <div className="search-field">
                  { this.renderJurisdictionFilter() }
                </div>
                <div className="search-field">
                  { this.renderDatePickers() }
                </div>
                <div className="search-field">
                  { this.renderCaseTitlePicker() }
                </div>
                <div className="search-field">
                  { this.renderJudgePicker() }
                </div>
              </div>
            </div>
            <button type="submit" className={`btn btn-flat search-button ${window.location.href.includes('issue_dashboard') ? 'search-button-issue-dashboard' : ''}`}>Search</button>
          </form>

          {
              !window.location.href.includes('issue_dashboard') && (
              <div className="results-container">
                <Results
                  search={this._search}
                  sortBy={this.state.sortBy}
                  resultsPerPage={this.state.resultsPerPage}
                  onSort={this.handleSort}
                  onPerPage={this.handlePerPage}
                  results={results}
                  isAdmin={isAdmin}
                  allResultIds={allResultIds}
                  federalCourts={federalCourts}
                  stateCourts={stateCourts}
                  selectedJurisdictions={this.state.selectedJurisdictions}
                  tags={tags}
                  favorites={favorites}
                  hasDynamicAccess={this.props.hasDynamicAccess}
                />
              </div>
              )
            }

          { tagSectionOpen && <div className="side-section-container-float">{this._renderTagSection()}</div> }
          { loadingModalOpen && this._renderLoadingModal() }
          { jurisdictionSectionOpen && <div className="side-section-container-float">{this._renderJurisdictionSection()}</div> }
          { searchFailedModalOpen && (<SearchFailedModal error={error} closeModal={() => this.setState({ searchFailedModalOpen: false })} />)}
          <ReactTooltip effect="solid" html delayHide={1000} />
        </div>
      </>
    );
  }
}

Search.propTypes = {
  jurisdictions: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  allResultIds: PropTypes.array.isRequired,
};

export default Search;
