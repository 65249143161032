import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import {
  FaCheckSquare,
  FaRegSquare,
  FaBookmark,
  FaRegBookmark,
  FaAngleUp,
  FaAngleDown, FaPencilAlt,
} from 'react-icons/all';
import { FaShareAlt } from 'react-icons/fa';

export default function RuleSummary({
  selectedRule, toggleHighlights, highlights, ruleType, toggleRuleBookmark, favorites, isAdmin,
}) {
  const [summaryVisible, setSummaryVisible] = useState(true);
  const rulePath = window.location.pathname;
  const fullPath = encodeURIComponent(`${window.location.href}?${Number(Date.now())}`);

  return (
    <div className="rule-summary">
      <div className="name-row">
        <div className="name">
          { selectedRule.number }
        </div>
        { isAdmin && (
          <button
            className="edit-button"
            style={{ marginRight: '1rem' }}
            onClick={() => {
              window.open(`/admin${rulePath}/edit`, '_blank').focus();
            }}
          >
            <FaPencilAlt />
          </button>
        )}
        { !selectedRule.is_private && (
          <button
            className="edit-button"
            style={{ marginRight: '1rem' }}
            title="Share on LinkedIn"
            onClick={() => {
              window.open(`https://www.linkedin.com/feed/?shareActive=true&shareUrl=${fullPath}?${Number(Date.now())}`, '_blank').focus();
            }}
          >
            <FaShareAlt />
          </button>
        )}
        <div className="bookmark" onClick={(e) => toggleRuleBookmark(e, selectedRule)}>
          { favorites?.rules !== undefined && favorites.rules.some(
            (rule) => (rule.id === selectedRule.rule_id),
          )
            ? <FaBookmark />
            : <FaRegBookmark />}
        </div>
      </div>
      { (ruleType === 'FederalRule' || ruleType === 'StateRule' || ruleType === 'DistrictRule') && (
      <div
        className={`summary-checkbox${highlights === true ? ' selected' : ''}`}
        onClick={toggleHighlights}
      >
        {highlights === true
          ? <FaCheckSquare />
          : <FaRegSquare />}
        Show eDiscovery Highlights
      </div>
      )}
      <div className="buttons">
        {/* { selectedRule.document.url */}
        {/*    && ( */}
        {/*      <a href={`/api/rules/download_pdf?url=${selectedRule.document.url}`} rel="noreferrer noopener" target="_blank" className="pdf"> */}
        {/*        <FaArrowDown /> */}
        {/*        Download PDF */}
        {/*      </a> */}
        {/*    )} */}
      </div>
      { selectedRule.summary && (
      <div className="summary">
        <div className="summary-button" onClick={() => setSummaryVisible(!summaryVisible)}>
          Summary&nbsp;&nbsp;&nbsp;
          {' '}
          { summaryVisible ? <FaAngleDown /> : <FaAngleUp /> }
        </div>
        <div>
          { summaryVisible && ReactHtmlParser(selectedRule.summary) }
        </div>
      </div>
      )}
    </div>
  );
}

RuleSummary.propTypes = {
  ruleType: PropTypes.string.isRequired,
  selectedRule: PropTypes.object,
  toggleHighlights: PropTypes.func.isRequired,
  highlights: PropTypes.bool.isRequired,
  toggleRuleBookmark: PropTypes.func.isRequired,
  favorites: PropTypes.object.isRequired,
};

RuleSummary.defaultProps = {
  selectedRule: null,
};
