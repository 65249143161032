import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { ClipLoader } from 'react-spinners';
import { Document, Page } from 'react-pdf';
import FileViewer from 'react-file-viewer';
import api from '../../../services/api';
import RuleSummary from './RuleSummary';
import { Pages } from '../../learning_center/DetailsContent/Pages';

export default function RuleDetails({
  rule, ruleType, favorites, isAdmin,
}) {
  const [buttonToggle, setButtonToggle] = useState(0);
  const [stateRule, setStateRule] = useState(null);
  const [highlights, setHighlights] = useState(false);
  const [bookmarks, setBookmarks] = useState(favorites || {});
  const [pages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const backUrl = window.document.referrer;
  const [mobileOpened, setMobileOpened] = useState(false);

  useEffect(() => {
    if (ruleType !== 'StateRule') return;
    api.rules.state.getRule(rule.rule_id).then((res) => setStateRule(res.message));
  }, []);

  const updateFavorites = () => api.favorites.get().then((result) => setBookmarks(result.favorites));

  const toggleHighlights = () => setHighlights(!highlights);

  const toggleRuleBookmark = (e, toggledRule) => {
    e.stopPropagation();
    let endpoint;

    switch (ruleType) {
      case 'FederalRule':
        endpoint = api.rules.federal;
        break;
      case 'StateRule':
        endpoint = api.rules.state;
        break;
      case 'DistrictRule':
        endpoint = api.rules.district;
        break;
      case 'AbaModelRule':
        endpoint = api.rules.aba;
        break;
      case 'RegulatoryRule':
        endpoint = api.rules.regulatory;
        break;
      default:
        break;
    }

    const ruleId = toggledRule.rule_id;
    if (bookmarks?.rules !== undefined && bookmarks.rules.some((favoriteRule) => favoriteRule.id === ruleId)) {
      endpoint.unfavorite(ruleId).then(() => updateFavorites());
    } else {
      endpoint.favorite(ruleId).then(() => updateFavorites());
    }
  };

  const processRuleHighlights = (html) => {
    let tagOpen = false;

    return ReactHtmlParser(
      html,
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        transform: (node) => {
          if ((node.data || '').includes('[eDiscovery]')) {
            tagOpen = true;
            return null;
          }

          if ((node.data || '').includes('[/eDiscovery]')) {
            tagOpen = false;
            return null;
          }

          // return undefined for default behavior
          if (tagOpen && (node.data || '').length > 0) {
            return highlights ? <div className="rule-highlight">{node.data}</div> : undefined;
          }

          return undefined;
        },
      },
    );
  };

  const navigatedFromCaseDigest = () => {
    const { referrer } = window.document;
    return referrer && new URL(referrer).hostname === window.location.hostname && /\/case_law\/\d+/.test(referrer);
  };

  const renderRule = (type, currentRule, summaryToggle) => {
    if (summaryToggle === 1) return ReactHtmlParser(currentRule.committee_notes);
    // If there is a document, show it instead
    if (currentRule.document && currentRule.document.url) {
      return (
        currentRule.document.url.includes('.docx') ? (
          <FileViewer
            fileType="docx"
            filePath={currentRule.document.url}
          />
        ) : (
          <>
            <Document
              file={currentRule.document.url}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              <Page pageNumber={pageNumber} renderMode="svg" />
            </Document>
            { pages && <Pages pageNumber={pageNumber} pages={pages} setPageNumber={setPageNumber} />}
            {' '}
          </>
        )
      );
    }
    if (type === 'RegulatoryRule') {
      return (
        <>
          <h2 className="rule-title">
            {currentRule.title}
          </h2>
          {ReactHtmlParser(currentRule.full_text)}
        </>
      );
    }
    // Everything elseState Rule
    return (
      <>
        <h2 className="rule-title">
          {currentRule.title}
        </h2>
        {processRuleHighlights(currentRule.full_text || currentRule.text)}
      </>
    );
  };

  return (
    <div className="react-rules">
      <div className="mobile-search-open" onClick={() => setMobileOpened(!mobileOpened)} />
      <div className={`left ${mobileOpened ? 'opened' : ''}`}>
        <div className="back-button">
          <a className="back" href={backUrl}>
            ← Back to
            {' '}
            {`${navigatedFromCaseDigest() ? 'case' : 'list'}`}
          </a>
        </div>
        <RuleSummary
          selectedRule={rule}
          toggleHighlights={toggleHighlights}
          highlights={highlights}
          ruleType={ruleType}
          toggleRuleBookmark={toggleRuleBookmark}
          favorites={bookmarks}
          isAdmin={isAdmin}
        />
      </div>
      <div className="right">
        <div className="rule-details">
          {
            ruleType === 'StateRule' && stateRule === null ? (
              <div className="loader">
                <ClipLoader color="rgba(0, 14, 87, 1)" />
                <div className="name">Loading</div>
              </div>
            ) : (
              <>
                <div className="buttons">
                  {
                    (ruleType !== 'RegulatoryRule') && (
                      <div className="toggle">
                        <div
                          className={`toggle-fulltext${buttonToggle === 0 ? ' selected' : ''}`}
                          onClick={() => setButtonToggle(0)}
                        >
                          Full text
                        </div>
                        <div
                          className={`toggle-notes${buttonToggle === 1 ? ' selected' : ''}`}
                          onClick={() => setButtonToggle(1)}
                        >
                          Committee notes
                        </div>
                      </div>
                    )
                  }
                </div>
                <div className="rule-content">
                  {(ruleType === 'StateRule'
                    ? renderRule(ruleType, stateRule, buttonToggle)
                    : renderRule(ruleType, rule, buttonToggle)
                  )}
                </div>
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}

RuleDetails.propTypes = {
  rule: PropTypes.object.isRequired,
  ruleType: PropTypes.string.isRequired,
  favorites: PropTypes.object.isRequired,
};
