import React from 'react';
import renderer from 'react-test-renderer';

import FederalRules from '../../../rules/src/FederalRules';

const federalRuleCategories = [
  {
    id: 1,
    name: 'Federal Rule Category #1',
    rules: [
      {
        id: 2,
        rule_id: 2,
        published: true,
        number: 'Number for Rule #1',
        title: 'This is a Title for Rule #1',
        tag_list: ['tag A', 'tag B'],
      },
      {
        id: 3,
        rule_id: 3,
        published: true,
        number: 'Number for Rule #2',
        title: 'This is a Title for Rule #2',
        tag_list: ['tag C', 'tag D'],
      },
    ],
  },
];

function createNodeMock(element) {
  const firstLink = document.createElement('a');
  const secondLink = document.createElement('a');
  if (element.type === 'div') {
    return {
      querySelectorAll: () => [firstLink, secondLink],
      getBoundingClientRect: () => ({
        bottom: 153.33333206176758,
        height: 28.11111068725586,
        left: 573.8194580078125,
        right: 1589.9027709960938,
        top: 125.22222137451172,
        width: 1016.083312988281,
        x: 573.8194580078125,
        y: 125.22222137451172,
      }),
    };
  }

  return null;
}

it('renders correctly with props', () => {
  const tree = renderer.create(<FederalRules
    federalRuleCategories={federalRuleCategories}
    favorites={{}}
    loading={false}
    toggleRuleBookmark={() => {}}
  />, { createNodeMock }).toJSON();
  expect(tree).toMatchSnapshot();
});
