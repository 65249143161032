import React from 'react';
import renderer from 'react-test-renderer';

import FederalRulesNavigator from '../../../rules/src/FederalRulesNavigator';

const federalRuleCategories = [
  {
    id: 1,
    name: 'Federal Rule Category #1',
    rules: [
      {
        id: 2,
        rule_id: 2,
        published: true,
        number: 'Number for Rule #1',
        title: 'This is a Title for Rule #1',
        tag_list: ['tag A', 'tag B'],
      },
      {
        id: 3,
        rule_id: 3,
        published: true,
        number: 'Number for Rule #2',
        title: 'This is a Title for Rule #2',
        tag_list: ['tag C', 'tag D'],
      },
    ],
  },
];

it('renders correctly with props', () => {
  const tree = renderer.create(<FederalRulesNavigator
    federalRuleCategories={federalRuleCategories}
    setFederalFilter={() => {}}
    federalFilter=""
  />).toJSON();
  expect(tree).toMatchSnapshot();
});
