import React, { useState } from 'react';
import chunk from 'lodash/chunk';
import 'react-sortable-tree/style.css';
import moment from 'moment';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import Api from '../../../services/api';
import Snackbar from '../../../services/snackbar';
import EdaSwitch from '../Switch';
import AsyncSelect from '../AsyncSelect';
import Select from '../Select';
import Dropdown, { DropdownItem } from '../Dropdown';

function renderTrialSubscription(account) {
  if (!account || !account.subscriptions) return 'N/A';
  const trialSubscription = account.subscriptions.findLast((sub) => sub.plan.name === 'Trial') || null;
  if (!trialSubscription) return 'N/A';
  return (
    <>
      {trialSubscription.plan.name}
      {' | '}
      {moment(trialSubscription.effective_from).format('MM/DD/YY')}
      {' - '}
      {moment(trialSubscription.effective_to).format('MM/DD/YY')}
    </>
  );
}

function renderSubscription(account) {
  if (!account) return 'N/A';
  if (account.account_type === 'eda_admin') return 'Admin';
  if (!account.valid_subscriptions) return 'N/A';

  const lastSubscription = account.valid_subscriptions.filter((sub) => !sub.plan.name.includes('Trial') && !sub.plan.name.includes('Academy')).pop();
  if (!lastSubscription) return 'N/A';

  return (
    <>
      {lastSubscription.plan.name}
      {' | '}
      {moment(lastSubscription.effective_from).format('MM/DD/YY')}
      {' - '}
      {moment(lastSubscription.effective_to).format('MM/DD/YY')}
    </>
  );
}

function renderTrialCancelled(account) {
  if (!account || !account.subscriptions) return '-';
  const trialSubscription = account.subscriptions.findLast((sub) => sub.plan.name === 'Trial') || null;
  const individualSubscription = account.subscriptions.findLast((sub) => sub.plan.name.toLowerCase().includes('individual')) || null;
  if (!trialSubscription || !individualSubscription) return '-';

  const startTrial = moment(trialSubscription.effective_from, 'YYYY-MM-DD');
  const startIndividual = moment(individualSubscription.effective_from, 'YYYY-MM-DD');
  const difference = startIndividual.diff(startTrial, 'days');

  return difference > 0 && difference < 15 ? difference : '-';
}

export default function Edit({
  user, roles, accountTypes, account, valid_subscriptions = [],
}) {
  const [data, setData] = useState({
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
    disabled: user?.disabled || false,
    user_role_ids: user?.user_role_ids || [],
    account: user?.account || account || null,
    subscription_id: user?.subscription_id || '',
    academy_subscription_id: user?.academy_subscription_id || '',
  });

  const [accounts, setAccounts] = useState([]);
  const [tab, setTab] = useState('user');
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [subscriptions, setSubscriptions] = useState(
    account ? ([...account.valid_subscriptions, ...account.valid_academy_subscriptions] || valid_subscriptions) : [],
  );

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value });
  };

  const handleUpdate = () => {
    if (user) {
      Api.admin.users.update(user.id, {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        disabled: data.disabled,
        user_role_ids: data.user_role_ids,
        account_id: data.account.id,
        subscription_id: data.subscription_id,
        academy_subscription_id: data.academy_subscription_id,
      }).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', 'User updated');
        window.location.reload();
      });
    } else {
      Api.admin.users.create({
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        disabled: data.disabled,
        user_role_ids: data.user_role_ids,
        account_id: data.account.id,
        subscription_id: data.subscription_id,
        academy_subscription_id: data.academy_subscription_id,
      }).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', 'User created');

        if (account) window.location.href = `/admin/accounts/${account.id}/edit`;
        else window.location.href = `/admin/users/${res.user.id}/edit`;
      });
    }
  };

  const loadOptions = (input) => Api.admin.accounts.get({ page: 1, name: input })
    .then((res) => {
      setAccounts(res.data.accounts);
      return res.data.accounts.map((acc) => ({ value: acc.id, label: acc.name }));
    });

  const handleSendConfirmation = () => {
    if (subscriptions.length < 1) {
      Snackbar.show('Requires at least one valid account subscription before confirmation.');
      return;
    }
    Api.admin.users.sendConfirmation(user.id).then((res) => {
      if (res.status !== 'OK') return;
      Snackbar.show(`Confirmation has been sent to - ${user.first_name} ${user.last_name}`);
    });
  };

  const handleTwoFactorDisable = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to disable Two Factor Authentication?')) {
      Api.admin.users.twoFactorSettings(user.id).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', `Two Factor Authentication was disabled for ${user.first_name} ${user.last_name}`);
        window.location.reload();
      });
    }
  };

  const handleTwoFactorEnable = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to enable Two Factor Authentication?')) {
      Api.admin.users.twoFactorSettings(user.id).then((res) => {
        if (res.status !== 'OK') return;
        sessionStorage.setItem('message', `Two Factor Authentication was enabled for ${user.first_name} ${user.last_name}`);
        window.location.reload();
      });
    }
  };

  const handleDelete = () => {
    Api.admin.users.delete(user.id).then(({ status }) => {
      if (status !== 'OK') return;

      sessionStorage.setItem('message', `User “${user.first_name} ${user.last_name}” has been deleted!`);
      window.location.href = '/admin/users';
    });
  };

  const renderRole = (role) => {
    if (!role) return null;

    const isSelected = data.user_role_ids.includes(role.id);
    return (
      <div className="role">
        <div className={`role-checkbox ${isSelected ? ' selected' : ''}`}>
          {
            isSelected
              ? (
                <ImCheckboxChecked
                  onClick={() => handleChange('user_role_ids', data.user_role_ids.filter((id) => id !== role.id))}
                />
              )
              : (
                <ImCheckboxUnchecked
                  onClick={() => handleChange('user_role_ids', [...data.user_role_ids, role.id])}
                  style={{ fill: '#E5E5E8', background: '#E5E5E8', borderRadius: 3 }}
                />
              )
          }
        </div>
        {role.name}
      </div>
    );
  };

  const getSubscriptionLabel = (id) => {
    const sub = subscriptions.find((s) => s.id === id);
    if (sub) return `${sub.plan.name} (${sub.effective_from}/${sub.effective_to})`;
    return 'None';
  };

  const handleCancelSubscription = (subscription) => {
    Api.admin.subscriptions.cancel(subscription.id).then(({ status }) => {
      if (status !== 'OK') return;

      sessionStorage.setItem('message', 'Subscription has been canceled!');
      window.location.reload();
    });
  };

  const handleReactivateSubscription = (subscription) => {
    Api.admin.subscriptions.reactivate(subscription.id).then(({ status }) => {
      if (status !== 'OK') return;

      sessionStorage.setItem('message', 'Subscription has been reactivated!');
      window.location.reload();
    });
  };

  return (
    <div className="admin-page react-admin-users-edit">
      <div className="header">
        <h2>
          <span
            className="back"
            onClick={() => {
              // eslint-disable-next-line
            history.back();
            }}
          >
            &lt;
          </span>
          <a href="/admin/users" className="back">Users</a>
          {' '}
          /
          {' '}
          { account && (
          <a href={`/admin/accounts/${account.id}/edit`} className="back">
            {account.name}
            {' '}
            /
          </a>
          ) }
          { user && `${user.first_name} ${user.last_name} / ` }
          { user ? 'Edit' : 'Add New' }
        </h2>
        <button type="button" className="button" onClick={handleUpdate} disabled={!data.firstName || !data.lastName || !data.email || !data.account}>
          { user ? 'Update User' : 'Create User' }
        </button>
        <div style={{ position: 'relative' }}>
          { Boolean(user) && (
            <Dropdown className="dots-dropdown" buttonContent="..." align="right">
              { !user['confirmed?'] && (
                <DropdownItem onClick={() => handleSendConfirmation()}>
                  Send Confirmation
                </DropdownItem>
              )}
              { user.two_factor_enabled ? (
                <DropdownItem onClick={() => handleTwoFactorDisable()}>
                  Disable Two Factor Authentication
                </DropdownItem>
              ) : (
                <DropdownItem onClick={() => handleTwoFactorEnable()}>
                  Enable Two Factor Authentication
                </DropdownItem>
              )}
              <DropdownItem onClick={() => setDeleteConfirmationVisible(true)}>
                Delete User
              </DropdownItem>
            </Dropdown>
          )}

          {deleteConfirmationVisible && (
            <div className="delete-confirmation">
              <p>Removing a user will affect all relevant records.</p>
              <p>Do you wish to proceed? This action cannot be undone.</p>
              <div className="buttons">
                <button type="button" className="button danger" onClick={handleDelete}>Yes</button>
                <button type="button" className="button" onClick={() => setDeleteConfirmationVisible(false)}>No</button>
              </div>
            </div>
          )}
        </div>

      </div>
      <div className="form">
        <div className="tabs">
          <button
            className={`tab ${tab === 'user' ? 'active' : ''}`}
            onClick={() => setTab('user')}
          >
            User Detail
          </button>
          <button
            className={`tab ${tab === 'account' ? 'active' : ''}`}
            onClick={() => setTab('account')}
          >
            Account Detail
          </button>
          { (account && account.account_type === 'individual')
            && (
            <button
              className={`tab ${tab === 'subscriptions' ? 'active' : ''}`}
              onClick={() => setTab('subscriptions')}
            >
              Account Subscriptions
            </button>
            )}
          <button
            className={`tab ${tab === 'ip' ? 'active' : ''}`}
            onClick={() => setTab('ip')}
          >
            IP Addresses
          </button>
        </div>

        { tab === 'user' && (
          <>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.firstName}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.lastName}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    placeholder="Type Here"
                    className="form-control"
                    value={data.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Account</label>
                  <AsyncSelect
                    value={data.account ? { id: data.account.id, label: data.account.name } : null}
                    onChange={(val) => {
                      const currentAccount = accounts.find((acc) => acc.id === val.value);
                      setSubscriptions([...currentAccount.valid_subscriptions, ...currentAccount.valid_academy_subscriptions] || []);
                      setData({
                        ...data,
                        subscription_id: '',
                        academy_subscription_id: '',
                        account: currentAccount,
                      });
                    }}
                    loadOptions={loadOptions}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Subscription</label>
                  <Select
                    value={{
                      value: data.subscription_id,
                      label: getSubscriptionLabel(data.subscription_id),
                    }}
                    onChange={(sub) => handleChange('subscription_id', sub.value)}
                    options={[
                      { value: '', label: 'None' },
                      ...subscriptions.filter((sub) => !sub.plan.name.includes('Academy'))
                        .map((sub) => ({ value: sub.id, label: getSubscriptionLabel(sub.id) })),
                    ]}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>Academy Subscription</label>
                  <Select
                    value={{
                      value: data.academy_subscription_id,
                      label: getSubscriptionLabel(data.academy_subscription_id),
                    }}
                    onChange={(sub) => handleChange('academy_subscription_id', sub.value)}
                    options={[
                      { value: '', label: 'None' },
                      ...subscriptions.filter((sub) => sub.plan.name.includes('Academy'))
                        .map((sub) => ({ value: sub.id, label: getSubscriptionLabel(sub.id) })),
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="switch">
              <EdaSwitch checked={data.disabled} onChange={() => handleChange('disabled', !data.disabled)} />
              <span>Disable User</span>
            </div>

            <table className="table user-dates">
              <tbody>
                <tr>
                  <td>Date Created</td>
                  <td>{ user ? moment(user.created_at).format('MM/DD/YY') : 'N/A'}</td>
                </tr>
                <tr>
                  <td>Last Login</td>
                  <td>{ user && user.last_sign_in_at ? moment(user.last_sign_in_at).format('MM/DD/YY hh:mma') : 'N/A'}</td>
                </tr>
                <tr>
                  <td>Account Type</td>
                  <td>{ accountTypes.find((t) => t.value === data.account?.account_type)?.label || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Subscription Plan & Date</td>
                  <td>{renderSubscription(data.account)}</td>
                </tr>
                <tr>
                  <td>Trial Type & Date</td>
                  <td>{renderTrialSubscription(account)}</td>
                </tr>
                <tr>
                  <td>Trial upgraded at (days)</td>
                  <td>{renderTrialCancelled(account)}</td>
                </tr>
              </tbody>
            </table>

            <div className="roles">
              <h3>Roles</h3>
              { chunk(roles, 2).map((row) => (
                <div className="row">
                  <div className="col-sm-4">
                    {renderRole(row[0])}
                  </div>
                  <div className="col-sm-4">
                    {renderRole(row[1])}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}

        { tab === 'account' && (
          <>
            <table className="table account-info-table">
              <tbody>
                <tr>
                  <td>Account Type</td>
                  <td>{ accountTypes.find((t) => t.value === data.account?.account_type)?.label || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Subscription Plan & Date</td>
                  <td>{renderSubscription(data.account)}</td>
                </tr>
                <tr>
                  <td>Trial Type & Date</td>
                  <td>{renderTrialSubscription(account)}</td>
                </tr>
              </tbody>
            </table>

            <h3>Primary Contact</h3>
            <table className="table account-info-table">
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{ data.account?.first_name || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{ data.account?.last_name || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>{ data.account?.primary_email || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{ data.account?.phone_number || 'N/A' }</td>
                </tr>
              </tbody>
            </table>

            <h3>Billing Contact</h3>
            <table className="table account-info-table">
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>{ data.account?.secondary_first_name || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>{ data.account?.secondary_last_name || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>{ data.account?.secondary_email || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{ data.account?.secondary_phone_number || 'N/A' }</td>
                </tr>
              </tbody>
            </table>

            <h3>Others</h3>
            <table className="table account-info-table">
              <tbody>
                <tr>
                  <td>Notes</td>
                  <td>{ data.account?.notes || 'N/A' }</td>
                </tr>
                <tr>
                  <td>Lead Source</td>
                  <td>{ data.account?.lead_source || 'N/A' }</td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        { tab === 'subscriptions' && (
          account.subscriptions.length === 0 ? (
            <div className="placeholder">
              There are no subscription details yet.
              <div className="empty-image" />
            </div>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Plan</th>
                  <th>Time Range</th>
                  <th>Seats</th>
                  <th>Interval</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {account.subscriptions.map((subscription) => (
                  <tr>
                    <td>{subscription.plan?.name || ''}</td>
                    <td>
                      {moment(subscription.effective_from).format('MM/DD/YYYY')}
                      {' - '}
                      {moment(subscription.effective_to).format('MM/DD/YYYY')}
                    </td>
                    <td>{`${subscription.seats_used} / ${subscription.number_of_seats}`}</td>
                    <td>{subscription.interval}</td>
                    <td>
                      $
                      {(subscription.amount / 100).toFixed(2)}
                    </td>
                    <td>
                      { subscription.status === 'active' && (
                      <div className="tag success">Active</div>
                      )}

                      { subscription.status === 'cancelled' && (
                      <div className="tag danger">Canceled</div>
                      )}

                      { subscription.status === 'cancelled_at_end_of_period' && (
                      <div className="tag danger">Canceled at the end of period</div>
                      )}
                    </td>
                    <td>
                      <Dropdown className="dots-dropdown" buttonContent="..." align="right">
                        { !subscription.plan.stripe_plan_id && (
                        <DropdownItem
                          onClick={() => { window.location.href = `/admin/accounts/${account.id}/subscriptions/${subscription.id}/edit`; }}
                        >
                          Edit Subscription
                        </DropdownItem>
                        )}
                        {subscription.status === 'cancelled_at_end_of_period' && (
                        <DropdownItem onClick={() => handleReactivateSubscription(subscription)}>
                          Reactivate Subscription
                        </DropdownItem>
                        )}
                        { subscription.status === 'active' && (
                        <DropdownItem onClick={() => handleCancelSubscription(subscription)}>
                          Cancel Subscription
                        </DropdownItem>
                        )}
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )}

        { tab === 'ip' && (
          <>
            <table className="table account-info-table">
              <thead>
                <tr>
                  <th>IP Address</th>
                  <th>Date of Access</th>
                </tr>
              </thead>
              <tbody>
                {user?.ip_addresses?.map((address) => (
                  <tr>
                    <td>{address.ip}</td>
                    <td>{moment(address.created_at).format('YYYY-MM-DD')}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {!user?.ip_addresses?.length && (
              <div className="placeholder">
                There are no tracked IP addresses yet.
                <div className="empty-image" />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}
