import React from 'react';
import PropTypes from 'prop-types';

class FederalRulesNavigator extends React.Component {
  render() {
    const { federalRuleCategories, setFederalFilter, federalFilter } = this.props;
    const categoriesCount = federalRuleCategories.reduce((a, b) => a + b.rules.length, 0);
    return (
      <div className="federal-rules-navigator">
        <div className="category-list">
          <div
            className={`category${federalFilter === 'All' ? ' selected' : ''}`}
            onClick={() => setFederalFilter('All')}
          >
            <div className="name">
              All
            </div>
            <div className="rule-count">
              { categoriesCount }
            </div>
          </div>
          { federalRuleCategories.map((category) => (
            <div
              className={`category${category.name === federalFilter ? ' selected' : ''}`}
              key={category.name}
              onClick={() => setFederalFilter(category.name)}
            >
              <div className="name">
                { category.name }
              </div>
              <div className="rule-count">
                { category.rules.length }
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

FederalRulesNavigator.propTypes = {
  federalRuleCategories: PropTypes.array.isRequired,
  setFederalFilter: PropTypes.func.isRequired,
  federalFilter: PropTypes.string.isRequired,
};

export default FederalRulesNavigator;
