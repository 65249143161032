import PropTypes from 'prop-types';
import React from 'react';

export default function SearchFailedModal({ error, closeModal }) {
  const renderError = () => {
    if (error === 'prefix_too_broad') {
      return (
        <p>
          Your search contains a prefix that is too broad. Please narrow the broad term or reach out to support using the chat function below or email us at
          <a href="mailto:support@ediscoveryassistant.com"> support@ediscoveryassistant.com </a>
          for assistance.
        </p>
      );
    }

    return (
      <p>
        Unable to perform search. Please try different filters or reach out to support using the chat function below or email us at
        <a href="mailto:support@ediscoveryassistant.com"> support@ediscoveryassistant.com </a>
        for assistance.
      </p>
    );
  };

  return (
    <div className="login-modal new-modal" onClick={closeModal} data-testid="login-modal">
      <div className="modal-dialog modal-md">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <div className="circle" />
          <div className="modal-header">
            <div className="modal-title">
              <img className="react-header-logo" src="/logo.svg" alt="" />
            </div>
          </div>
          <div className="modal-body">
            {renderError()}
          </div>
        </div>
      </div>
    </div>
  );
}

SearchFailedModal.propTypes = {
  error: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};
