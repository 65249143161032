import React from 'react';
import Header from './Header';
import Hero from './Hero';
import Filters from './Filters';

export default function Modules({
  topics, currentUser, isAdmin, tags, academySubscription,
}) {
  return (
    <div className="react-lc-page lc-dashboard">
      <Header currentUser={currentUser} isAdmin={isAdmin} academySubscription={academySubscription} />
      <Hero
        title="Modules"
        subtitle="Key topics in ediscovery broken down by experts"
      >
        <Filters tags={tags} topics={topics} />
      </Hero>
      <div className="section">
        <div className="modules-list">
          {topics?.map((topic) => (
            <div className="module-container" key={topic.id}>
              <div className="backdrop1" />
              <div className="backdrop2" />
              <div className="module">
                <p className="name">{topic.name}</p>
                <p className="description">
                  {topic.description || ''}
                </p>
                <a href={`/media_category/${topic.id}`}>View Module</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
