import React from 'react';
import PropTypes from 'prop-types';

class RegulatoryRulesNavigator extends React.Component {
  render() {
    const { setRegulatoryFilter, regulatoryFilter } = this.props;
    const regulatoryAgencies = this.props.regulatoryAgencies.map((agency) => Object.assign(agency, { regulatory_rules: agency.regulatory_rules.filter((rule) => rule.published_revision.published) }));
    const agenciesCount = regulatoryAgencies.reduce((a, b) => a + parseInt(b.regulatory_rules?.length), 0);

    return (
      <div className="regulatory-rules-navigator">
        <div className="category-list">
          <div
            className={`category${regulatoryFilter === 'All' ? ' selected' : ''}`}
            onClick={() => setRegulatoryFilter('All')}
          >
            <div className="name">
              All
            </div>
            <div className="rule-count">
              { agenciesCount + (agenciesCount === 1 ? ' rule' : ' rules') }
            </div>
          </div>
          { regulatoryAgencies.map((agency) => (
            <div
              className={`category${regulatoryFilter === agency.name ? ' selected' : ''}`}
              key={agency.name}
              onClick={() => setRegulatoryFilter(agency.name)}
            >
              <div className="name">
                { agency.name }
              </div>
              <div className="rule-count">
                { agency.regulatory_rules.length
                                + (agency.regulatory_rules.length === 1 ? ' rule' : ' rules') }
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

RegulatoryRulesNavigator.propTypes = {
  regulatoryAgencies: PropTypes.array.isRequired,
  setRegulatoryFilter: PropTypes.func.isRequired,
  regulatoryFilter: PropTypes.string.isRequired,
};

export default RegulatoryRulesNavigator;
