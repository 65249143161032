// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`renders correctly with props 1`] = `
<div
  className="react-rules"
>
  <div
    className="mobile-search-open"
    onClick={[Function]}
  />
  <div
    className="left "
  >
    <div
      className="back-button"
    >
      <a
        className="back"
        href=""
      >
        ← Back to
         
        list
      </a>
    </div>
    <div
      className="rule-summary"
    >
      <div
        className="name-row"
      >
        <div
          className="name"
        >
          Number for Rule Details
        </div>
        <button
          className="edit-button"
          onClick={[Function]}
          style={
            Object {
              "marginRight": "1rem",
            }
          }
          title="Share on LinkedIn"
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 448 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M352 320c-22.608 0-43.387 7.819-59.79 20.895l-102.486-64.054a96.551 96.551 0 0 0 0-41.683l102.486-64.054C308.613 184.181 329.392 192 352 192c53.019 0 96-42.981 96-96S405.019 0 352 0s-96 42.981-96 96c0 7.158.79 14.13 2.276 20.841L155.79 180.895C139.387 167.819 118.608 160 96 160c-53.019 0-96 42.981-96 96s42.981 96 96 96c22.608 0 43.387-7.819 59.79-20.895l102.486 64.054A96.301 96.301 0 0 0 256 416c0 53.019 42.981 96 96 96s96-42.981 96-96-42.981-96-96-96z"
            />
          </svg>
        </button>
        <div
          className="bookmark"
          onClick={[Function]}
        >
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 384 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M336 0H48C21.49 0 0 21.49 0 48v464l192-112 192 112V48c0-26.51-21.49-48-48-48zm0 428.43l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.314 0 6 2.683 6 5.996V428.43z"
            />
          </svg>
        </div>
      </div>
      <div
        className="summary-checkbox"
        onClick={[Function]}
      >
        <svg
          fill="currentColor"
          height="1em"
          stroke="currentColor"
          strokeWidth="0"
          style={
            Object {
              "color": undefined,
            }
          }
          viewBox="0 0 448 512"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
          />
        </svg>
        Show eDiscovery Highlights
      </div>
      <div
        className="buttons"
      />
      <div
        className="summary"
      >
        <div
          className="summary-button"
          onClick={[Function]}
        >
          Summary   
           
          <svg
            fill="currentColor"
            height="1em"
            stroke="currentColor"
            strokeWidth="0"
            style={
              Object {
                "color": undefined,
              }
            }
            viewBox="0 0 320 512"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
            />
          </svg>
        </div>
        <div>
          Summary for Rule Details
        </div>
      </div>
    </div>
  </div>
  <div
    className="right"
  >
    <div
      className="rule-details"
    >
      <div
        className="buttons"
      >
        <div
          className="toggle"
        >
          <div
            className="toggle-fulltext selected"
            onClick={[Function]}
          >
            Full text
          </div>
          <div
            className="toggle-notes"
            onClick={[Function]}
          >
            Committee notes
          </div>
        </div>
      </div>
      <div
        className="rule-content"
      >
        <h2
          className="rule-title"
        >
          This is a Title
        </h2>
        <p>
          This is a text for Rule Details
        </p>
      </div>
    </div>
  </div>
</div>
`;
